import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import jwt from 'jwt-decode';
// import newAlert from 'src/components/newAlert';
import { useHubspotChat } from 'src/core/layout/hubSpot';
import { CompanyNotification } from 'src/components/common/company_notification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Spin } from 'antd';
import AuthChecker from './core/routes/AuthChecker';
import { WS_CONNECT, WS_DISCONNECT } from './core/actions/socket';
import { RSA_STORE_SERVER_KEY } from './core/actions/rsa';
import { set_jwt, remove_jwt } from './core/actions/jwt_token';
import { set_version } from './core/actions/version';
import { add_notification } from './core/actions/notification';
import { set_user_role } from './core/actions/user_role';
import { set_notification_type } from './core/actions/notificationType';
import { set_role } from './core/actions/role';
import { start_loading, end_loading, is_loading } from './core/actions/loader';
import 'antd/dist/antd.css';
import '@ant-design/pro-card/dist/card.css';
import './assets/scss/app.scss';

function App() {
  const antIcon = <div className="whirly-loader" />;
  const dispatch = useDispatch();
  // eslint-disable-next-line func-names
  console.log = function () {};

  const { socket, loader, jwt_token } = useSelector((state) => ({
    socket: state.socket,
    loader: state.loader,
    jwt_token: state.jwt_token,
  }));
  const [loading, seTloading] = useState(true);
  const [count, seTcount] = useState({ count: 0, msg: '' });

  // const { removeWidget } = useHubspotChat(
  //   process.env.HUBSPOT_PORTAL_ID || 21532402
  // );
  useHubspotChat(process.env.HUBSPOT_PORTAL_ID || 21532402);

  useEffect(() => {
    const { jwt_1: jwt_1_TEMP } = jwt_token;
    let jwt_decoded = null;
    if (jwt_1_TEMP) {
      jwt_decoded = jwt(jwt_1_TEMP);
    }
    if (jwt_decoded?.user_state === 1 && jwt_decoded?.company_state === 1) {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');
        const {
          year,
          year_end,
          permission_list_redux = [],
          is_first_year,
          default_role_id,
        } = data?.content;
        dispatch(set_user_role({ default_role_id }));
        dispatch(set_role([...permission_list_redux]));
        if (year) {
          dispatch(set_version({ year, year_end, is_first_year }));
        }
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts006_get_active_version',
        content: { jwt: localStorage.getItem('taxjwt') || null },
      });
      dispatch(start_loading({}));
    }
  }, [jwt_token]);

  useEffect(() => {
    if (socket) {
      socket.removeAllListeners('server_key');
      socket.on('server_key', (data) => {
        dispatch(RSA_STORE_SERVER_KEY(data));
      });
      // socket.removeAllListeners('check_cookie_rsp');
      socket.on('check_cookie_rsp', (data) => {
        if (data.jwt !== null) {
          if (data.jwt.toString() !== jwt_token.jwt_1) {
            socket.emit(
              'changeRoom',
              {
                jwt: data.jwt,
                device_token: localStorage.getItem('device_token'),
              },
              (response) => {
                if (response?.status === 0) {
                  localStorage.setItem('taxjwt', data?.jwt.toString());
                  dispatch(
                    set_jwt({
                      user_state: jwt(data?.jwt).user_state.toString(),
                      company_state: jwt(data?.jwt).company_state.toString(),
                      jwt_1: data?.jwt.toString(),
                    })
                  );
                }
              }
            );
          }
        } else {
          localStorage.removeItem('taxjwt');
          dispatch(remove_jwt());
          // removeWidget();
          // newAlert({ type: 'warning', msg: 'Системд нэвтрэхэд алдаа гарлаа' });
        }
      });
      // socket.removeAllListeners('set_cookie');
      socket.on('set_cookie', (data) => {
        if (data.jwt !== null) {
          if (data.jwt.toString() !== jwt_token.jwt_1) {
            localStorage.setItem('taxjwt', data?.jwt.toString());
            dispatch(
              set_jwt({
                user_state: jwt(data?.jwt).user_state.toString(),
                company_state: jwt(data?.jwt).company_state.toString(),
                jwt_1: data?.jwt.toString(),
              })
            );
          }
        } else {
          localStorage.removeItem('taxjwt');
          dispatch(remove_jwt());
          // removeWidget();
          // newAlert({ type: 'warning', msg: 'Системд нэвтрэхэд алдаа гарлаа' });
        }
      });
      socket.removeAllListeners('drop_to_company_select');
      socket.on('drop_to_company_select', (data) => {
        if (localStorage.getItem('taxjwt')) {
          CompanyNotification({
            title: 'Мэдэгдэл',
            text: data.content.Message,
          });
          localStorage.removeItem('etaxtoken');
          localStorage.setItem('notifications', JSON.stringify([]));
          socket.emit('send_to_company_select', {
            channel: 'ss002_auth_manage',
            content: {},
            tag: { jwt_1: localStorage.getItem('taxjwt') },
          });
        }
      });
      socket.removeAllListeners('double_login_force_logout');
      socket.on('double_login_force_logout', () => {
        if (localStorage.getItem('taxjwt')) {
          // removeWidget();
          socket.emit(
            'leaveRoom',
            { jwt: localStorage.getItem('taxjwt') },
            (response) => {
              if (response?.status === 0) {
                seTcount({
                  count: count.count + 1,
                  msg: 'Таны эрхээр өөр газраас хандсан тул системээс гаргаж байна',
                });
              }
            }
          );
        }
      });
      socket.removeAllListeners('user_force_logout');
      socket.on('user_force_logout', () => {
        if (localStorage.getItem('taxjwt')) {
          // removeWidget();
          socket.emit(
            'leaveRoom',
            { jwt: localStorage.getItem('taxjwt') },
            (response) => {
              if (response?.status === 0) {
                seTcount({
                  count: count.count + 1,
                  msg: 'Нэвтрэх хүчинтэй хугацаа дууссан тул таныг системээс гаргаж байна',
                });
              }
            }
          );
        }
      });
      socket.removeAllListeners('show_response_message');
      socket.on('show_response_message', (data) => {
        dispatch(set_notification_type(data?.content?.title));
        CompanyNotification({
          title: data?.content?.title || null,
          text: data?.content?.Message,
        });
      });
      socket.removeAllListeners('receive_notification');
      socket.on('receive_notification', ({ content }) => {
        if (content?.type === 'file') {
          dispatch(is_loading('done'));
        }

        // Retrieve current notifications from localStorage
        // eslint-disable-next-line operator-linebreak
        const currentNotifications =
          JSON.parse(localStorage.getItem('notifications')) || [];

        // Determine the count or last index
        const lastIndex = currentNotifications.length + 1;

        // Create a new notification object with the lastIndex as its ID
        const newNotification = {
          id: lastIndex,
          title: content?.title,
          description: content?.description,
          read: false,
        };

        // Update localStorage with the new notification
        const updatedNotifications = [...currentNotifications, newNotification];
        localStorage.setItem(
          'notifications',
          JSON.stringify(updatedNotifications)
        );
        dispatch(add_notification(lastIndex));
      });
      // socket.removeAllListeners('connect_error');
      socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });
      // socket.removeAllListeners('error');
      socket.on('error', (error) => {
        console.error('WebSocket error:', error);
      });
    } else if (!socket) {
      if (process.env.NODE_ENV) {
        dispatch(WS_CONNECT({ ip: process.env.REACT_APP_SOCKET_URL }));
      } else {
        dispatch(WS_CONNECT({ ip: '/' }));
      }
    }
  }, [socket]);

  useEffect(() => {
    if (count.count === 1) {
      localStorage.removeItem('taxjwt');
      dispatch(remove_jwt());
      CompanyNotification({
        title: 'Мэдэгдэл',
        text: count.msg,
      });
      WS_DISCONNECT();
    }
  }, [count]);

  useEffect(() => {
    if (
      localStorage.getItem('notifications') !== null &&
      localStorage.getItem('notifications') !== undefined
    ) {
      // console.log('The tag notifications exists in localStorage.');
    } else {
      localStorage.setItem('notifications', JSON.stringify([]));
      // console.log('The tag notifications does not exist in localStorage.');
    }
    setTimeout(() => {
      seTloading(false);
    }, 1000);
  }, []);

  if (loading) {
    return (
      <div className="loader-container-loading">
        <Spin className="loader-spin" indicator={antIcon} spinning />
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
        icon
      />
      <div
        className={
          loader.loading ? 'loader-container-loading' : 'loader-container-idle'
        }
      >
        <Spin
          className="loader-spin"
          indicator={antIcon}
          spinning={loader.loading}
        />
      </div>
      <Router>
        <Switch>
          <AuthChecker />
        </Switch>
      </Router>
    </>
  );
}

export default App;
