import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import newAlert from 'src/components/newAlert';
import { CalculatorOutlined } from '@ant-design/icons';

import { Button as AntButton, Table as AntTable } from 'antd';

import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import { dans_columns } from '../data/dataStructure';

export default function ZuruuAvlagaUglug() {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [tableData, setTableData] = useState([]);
  const [avlagaSum, setAvlagaSum] = useState({});
  const [uglugSum, setUglugSum] = useState({});
  const [turZuruuList, setTurZuruuList] = React.useState([]);
  const [totalData, setTotalData] = useState(5);

  const handleSelectDans = (dans_code, tur_zuruu_turul) => {
    const propName = 'dans_code';
    const propName2 = 'tab';
    const propName3 = 'tur_zuruu_turul';
    const proplist = ['Hariltsagch', 'page2', 'size2'];
    if (query.get(propName)) {
      query.set(propName, dans_code);
    } else {
      query.append(propName, dans_code);
    }
    if (query.get(propName2)) {
      query.set(propName2, '2');
    } else {
      query.append(propName2, '2');
    }
    if (query.get(propName3)) {
      query.set(propName3, tur_zuruu_turul);
    } else {
      query.append(propName3, tur_zuruu_turul);
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  const fetch_TC_diff_type = async () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        setTurZuruuList(data.content.json_array);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        action_type: 'get_list',
        data_type: '0',
      },
    });
  };

  const calculateTempDiff = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content?.status === '0') {
        newAlert({ type: 'success', msg: 'Түр зөрүү амжилттай татагдлаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts002_00_tur_zuruu',
      content: {
        action_type: 'eh_uld_calculate_tur_zuruu',
      },
    });
    dispatch(start_loading({}));
  };

  const fetch_list_data = () => {
    if (turZuruuList.length > 0) {
      const dans_list = turZuruuList.map((d) => d.dans_dugaar);
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');

        if (data.content.status === '0') {
          const merged = [];
          const result_array = data.content?.tovchoo_list || [];
          for (let i = 0; i < result_array.length; i += 1) {
            merged.push({
              ...result_array[i],
              ...turZuruuList.find(
                (x) => x.dans_dugaar === result_array[i].dans_code
              ),
            });
          }
          setTableData(merged);
          setAvlagaSum(data.content?.avlaga_sum);
          setUglugSum(data.content?.uglug_sum);
          setTotalData(data.content?.total || 20);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }

        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts002_00_zuruu',
        content: {
          action_type: 'eh_uld_zuruu_dansaar',
          month: '13',
          version: version.version_number,
          year: version.year,
          dans_list,
          page: query.get('page') || 1,
          size: query.get('size') || 20,
        },
      });
      dispatch(start_loading({}));
    }
  };

  useEffect(() => {
    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetch_list_data();
      }
    } else fetch_list_data();
  }, [query, turZuruuList]);

  useEffect(() => {
    fetch_TC_diff_type();
  }, []);

  return (
    <>
      <div className="table-wrapper">
        <div className="inside-table">
          <Table
            columns={dans_columns(handleSelectDans)}
            dataSource={tableData}
            total={totalData}
            scroll={{
              y: 'calc(100vh - 370px)',
            }}
            customize={() => (
              <AntTable.Summary fixed>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell />
                  <AntTable.Summary.Cell align="right">
                    Авлагын нийт дүн:
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.etssiin_dun)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.etssiin_dun_tatvar)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(avlagaSum.zuruu)}
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
                <AntTable.Summary.Row>
                  <AntTable.Summary.Cell />
                  <AntTable.Summary.Cell align="right">
                    Өглөгийн нийт дүн:
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.etssiin_dun)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.etssiin_dun_tatvar)}
                  </AntTable.Summary.Cell>
                  <AntTable.Summary.Cell align="right">
                    {roundFloat(uglugSum.zuruu)}
                  </AntTable.Summary.Cell>
                </AntTable.Summary.Row>
              </AntTable.Summary>
            )}
            title={() => (
              <AntButton
                className="add-btn"
                type="primary"
                icon={<CalculatorOutlined />}
                onClick={calculateTempDiff}
                size="small"
              >
                Түр зөрүү татах
              </AntButton>
            )}
          />
        </div>
      </div>
    </>
  );
}
