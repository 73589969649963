import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Typography,
  Input,
  Divider,
} from 'antd';
import newAlert from 'src/components/newAlert';
import CustomDatePicker from 'src/components/common/datePicker';
import { customColumns, customSummary } from './dataStructure/data';

import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [tableDataSum, setTableDataSum] = useState({});
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const [addForm] = Form.useForm();
  const [deleteForm] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');
      setTableData([]);

      if (data_temp?.status === '0') {
        if ('json_array' in data_temp?.content) {
          setTableData(data_temp.content.json_array);
          setTableDataSum(data_temp.content.sum_data);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: { action_type: 'get_sanhuu_hm02_5', month: String(month) },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'delete_sanhuu_hm02_5',
        id: deleteData.delete_id,
      },
    });

    dispatch(start_loading({}));
  };

  const addValue = (saveData) => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else if (data_temp.status === '5') {
        newAlert({ type: 'error', msg: 'Зөв дүн оруулна уу' });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'add_sanhuu_hm02_5',
        action_data: saveData,
        month: String(data_date.month),
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date]);

  const addAction = () => {
    socket.on('request_out', (data) => {
      const data_temp = data;
      socket.removeAllListeners('request_out');

      if (data_temp?.status === '0') {
        addForm.setFieldsValue({
          unelgee_dun: roundFloat(data_temp?.content?.unelgee_dun),
          tur_notlogdoh_dun: roundFloat(data_temp?.content?.tur_notlogdoh_dun),
          busad_notlogdoh_dun: roundFloat(
            data_temp?.content?.busad_notlogdoh_dun
          ),
        });
        setAddVisible(true);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'info_sanhuu_hm02_5',
        month: String(data_date.month),
      },
    });

    dispatch(start_loading({}));
  };

  const deleteAction = (row) => {
    deleteForm.setFieldsValue({
      delete_id: row?.id,
    });

    setDeleteVisible(true);
  };

  const title = () => (
    <>
      <div className="table-title-text-center">
        ТӨРИЙН БАЙГУУЛЛАГААС ОЛГОСОН ЭРХ ЭЗЭМШИГЧИЙН ЭРХ БОРЛУУЛСАН, ШИЛЖҮҮЛСНИЙ
        ОРЛОГЫН МЭДЭЭ /ХМ-02(5)/
      </div>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          addAction();
        }}
      >
        Нэмэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container-1600">
        <Table
          columns={customColumns({
            customDelete: deleteAction,
          })}
          dataSource={tableData}
          scroll={
            {
              // y: 'max-content',
              // x: 'max-content',
            }
          }
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableDataSum,
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Бүртгэл устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Бүртгэлийн дугаар үүсгэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={750}
        footer={null}
      >
        <AddModal
          onClose={() => {
            setAddVisible(false);
          }}
          form={addForm}
          saveValues={addValue}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 18 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 6 },
  },
};

const AddModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        // validateMessages={validateMessages}
        size="small"
      >
        <Form.Item
          name="ner"
          label="Эрх худалдаж авсан, шилжүүлэн авсан этгээдийн нэр"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="ttd"
          label="Эрх худалдаж авсан, шилжүүлэн авсан этгээдийн Регистрийн дугаар"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="erh_turul"
          label="Эрхийн төрөл"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="zuvshuurul_dugaar"
          label="Тусгай зөвшөөрлийн дугаар"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="ognoo"
          label="Огноо"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <CustomDatePicker customClass="ww-100 h-25" />
        </Form.Item>
        <Divider />
        <Form.Item
          name="unelgee_dun"
          label="Үнэлгээ"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Form.Item
          name="tur_notlogdoh_dun"
          label="Төрийн байгууллагад төлсөн баримтаар нотлогдох төлбөр"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Form.Item
          name="busad_notlogdoh_dun"
          label="Бусдаас худалдан авахад төлсөн, шилжүүлсэн баримтаар нотлогдох төлбөр"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="delete_id" hidden />
        <Text>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
