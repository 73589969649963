import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Table } from 'src/components';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Select,
  Divider,
  Typography,
  Input,
  Checkbox,
} from 'antd';
import newAlert from 'src/components/newAlert';
import { customColumns, customSummary } from './dataStructure/data';
import { start_loading, end_loading } from '../../../core/actions/loader';

const { Text } = Typography;

const MainPage = () => {
  const dispatch = useDispatch();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);

  const [tableData, setTableData] = useState([]);
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const [addForm] = Form.useForm();
  const [deleteForm] = Form.useForm();

  const generateTailan = (month) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data?.status === '0') {
        if ('json_array' in data?.content) {
          setTableData(data.content);
        } else {
          newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        }
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: { action_type: 'get_sanhuu_hm02_6', month: String(month) },
    });

    dispatch(start_loading({}));
  };

  const deleteValue = (deleteData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай устгагдлаа' });
        generateTailan(data_date.month);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'delete_sanhuu_hm02_6',
        id: deleteData.delete_id,
      },
    });

    dispatch(start_loading({}));
  };

  const addValue = (saveData) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хадгалагдлаа' });
        generateTailan(data_date.month);
      } else if (data.status === '5') {
        newAlert({ type: 'error', msg: 'Зөв дүн оруулна уу' });
        dispatch(end_loading({}));
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tailan_control',
      content: {
        action_type: 'add_sanhuu_hm02_6',
        action_data: saveData,
        query_data: tableData?.sum_data?.query_data,
        month: String(data_date.month),
      },
    });

    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (data_date) {
      generateTailan(data_date.month);
    }
  }, [data_date]);

  const deleteAction = (row) => {
    if (row?.id) {
      deleteForm.setFieldsValue({
        delete_id: row?.id,
      });

      setDeleteVisible(true);
    }
  };

  const addAction = () => {
    addForm.setFieldsValue({
      tatvar_suutgah_check: false,
      tatvar_suutgah_dun: roundFloat(0.0),
    });

    setAddVisible(true);
  };

  const title = () => (
    <>
      <div className="table-title-text-center">
        Хуулийн дагуу бусдад татвар суутгуулах орлогын мэдээ /ХМ-02(6)/
      </div>
      <Button
        className="add-btn"
        type="primary"
        size="small"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          addAction();
        }}
      >
        Нэмэх
      </Button>
    </>
  );

  return (
    <>
      <div className="table-antd-div tailan-container-1600">
        <Table
          columns={customColumns({
            customDelete: deleteAction,
          })}
          dataSource={tableData?.json_array}
          summaryList={{
            sumColumn: customSummary,
            sumDataSource: tableData?.sum_data,
          }}
          title={title}
        />
      </div>
      <Modal
        className="custom-modal"
        title="Бүртгэл устгах"
        visible={deleteVisible}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <DeleteModal
          onClose={() => {
            setDeleteVisible(false);
          }}
          form={deleteForm}
          deleteValue={deleteValue}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Бүртгэл нэмэх"
        visible={addVisible}
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
      >
        <EditModal
          onClose={() => {
            setAddVisible(false);
          }}
          form={addForm}
          saveValues={addValue}
          tableData={tableData}
        />
      </Modal>
    </>
  );
};

const layout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
  },
};

const EditModal = ({
  onClose = () => {},
  form = null,
  saveValues = () => {},
  tableData = {},
}) => {
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item
          name="dans_id"
          label="Орлогын төрөл"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Select defaultValue={null}>
            <Select.Option value={null}>---</Select.Option>
            {tableData?.sum_data?.query_data?.map((i) => (
              <Select.Option value={i?.dans_id}>
                {roundFloat(i?.tatvar_dans_dun)} : {i?.tatvar_dans}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="tatvar_dans_dun"
          label="Татвар ногдуулах орлого"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" addonBefore="₮" />
        </Form.Item>
        <Form.Item
          name="tatvar_suutgah_check"
          label="Татвар суутгагдсан эсэх"
          labelAlign="left"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="tatvar_suutgah_dun"
          label="Суутгуулсан татвар"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
          normalize={onlyNumber}
        >
          <Input className="w-100 text-align-r" addonBefore="₮" />
        </Form.Item>
        <Divider plain>Эрх худалдаж авсан, шилжүүлэн авсан этгээд</Divider>
        <Form.Item
          name="ner"
          label="Нэр"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Form.Item
          name="ttd"
          label="Регистрийн дугаар"
          labelAlign="left"
          rules={[
            {
              required: true,
              message: '',
            },
          ]}
        >
          <Input className="w-100" />
        </Form.Item>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Хадгалах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const DeleteModal = ({
  onClose = () => {},
  form = null,
  deleteValue = () => {},
}) => {
  const onFinish = (values) => {
    deleteValue(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div className="dans-medeelel-container">
      <Form
        form={form}
        onFinish={onFinish}
        className="register-form"
        {...layout}
        size="small"
      >
        <Form.Item name="delete_id" hidden />
        <Text>Та энэ үйлдлийг хийхдээ итгэлтэй байна уу?</Text>
        <Row>
          <Col span={24} className="text-align-r">
            <Form.Item noStyle>
              <Button
                onClick={onCancel}
                className="cancel-btn"
                type="primary"
                shape="round"
              >
                Цуцлах
              </Button>
              <Button
                className="confirm-btn ml-5"
                type="primary"
                shape="round"
                htmlType="submit"
              >
                Устгах
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
