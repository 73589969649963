import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button as AntButton, Modal, Drawer, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { Table, Warning } from 'src/components';
import { useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import CanView from 'src/components/permission/canview';
import newAlert from 'src/components/newAlert';
import {
  mainColumns,
  mainColumnsSum,
  mainDansSumColumns,
  mainDansSumColumnsSum,
} from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { BurtgelDans } from './burtgelDans';
import { DahinUnelgeeAll } from './DahinUnelgeeAll';
import { ErrorList } from './ErrorList';
import { NewFileAddDahin } from './fileAdd';

const UndsenHurungu = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [sumType, seTsumType] = useState();
  const [selectedHurungu, seTselectedHurungu] = useState({});
  const [burtgel, seTburtgel] = useState(false);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });
  const [visibleDahinAll, setVisibleDahinAll] = useState(false);
  const [errorList, seTerrorList] = useState({
    have_error: false,
    errors: [],
    burtgel_errors: {},
  });
  const [visible, seTvisible] = useState({
    towcho: false,
    dahin: false,
  });

  const typesList = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];

  const onSelectHurungu = (row) => {
    seTselectedHurungu(row);
    seTburtgel(true);
  };

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      type: sumType || 'hurungu',
      dans_dugaar: query.get('dans_dugaar'),
      hurungu: query.get('hurungu'),
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'ehnii_uldegdel_page', page, size, search },
    });
    dispatch(start_loading({}));
  };

  const clearPage = () => {
    const proplist = ['page', 'size'];
    let not_change = true;
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
        not_change = false;
      }
    });
    if (not_change) {
      select_data();
    } else {
      history.push({ search: query.toString() });
    }
  };

  useEffect(() => {
    if (sumType) {
      clearPage();
    }
  }, [sumType]);

  useEffect(() => {
    select_data();
  }, [query]);

  const after_handleUploadDahin = (content) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        select_data();
        newAlert({ type: 'success', msg: data.message });
      } else {
        if (data?.content?.error_list) {
          seTerrorList({
            have_error: true,
            errors: data?.content?.error_list,
            burtgel_errors: data?.content?.burtgel_dugaar_list_error,
          });
        }
        newAlert({ type: 'error', msg: data.message });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_modify',
      content: {
        action_type: 'dahin_unelgee_file',
        file_name: content?.json_array,
      },
    });
  };

  const title = () => (
    <Row>
      <>
        {!tableData?.validtion_info?.validated ? (
          <Warning show listWarning={['Холбосон дүн таараагүй байна']} />
        ) : (
          <></>
        )}
      </>
      {version?.is_first_year ? (
        <CanView allowedRole="006_1">
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              seTvisible({
                ...visible,
                dahin: true,
              });
            }}
          >
            Өртгийн өөрчлөлтийн жагсаалт оруулах
          </AntButton>
        </CanView>
      ) : null}
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          setVisibleDahinAll(true);
        }}
      >
        Өртгийн өөрчлөлтийн мэдээлэл
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          seTsumType(sumType === 'dans' ? 'hurungu' : 'dans');
        }}
      >
        {sumType === 'dans' ? 'Дансаар' : 'Хөрөнгөөр'}
      </AntButton>
    </Row>
  );

  const onCancel = (index) => {
    seTvisible({
      ...visible,
      [index]: false,
    });
  };

  const onCancelDahinUnelgeeAll = () => {
    setVisibleDahinAll(false);
  };

  const onCancelError = () => {
    seTerrorList({
      have_error: false,
      errors: [],
      burtgel_errors: {},
    });
  };

  const mainTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={mainColumns({ onSelectHurungu })}
        total={tableData?.total_size}
        dataSource={tableData?.result_list}
        scroll={{
          y: 'calc(100vh - 370px)',
        }}
        summaryList={{
          sumColumn: mainColumnsSum,
          sumDataSource: tableData?.total_list,
        }}
      />
    </div>
  );

  const mainDansTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={mainDansSumColumns()}
        total={tableData?.total_size}
        dataSource={tableData?.result_list}
        scroll={{
          y: 'calc(100vh - 370px)',
        }}
        summaryList={{
          sumColumn: mainDansSumColumnsSum,
          sumDataSource: tableData?.total_list,
        }}
      />
    </div>
  );

  const onCancelBurtgel = () => {
    seTburtgel(false);
    seTselectedHurungu({});
    select_data();
  };

  return (
    <>
      <ErrorList onCancel={onCancelError} errorList={errorList} />
      <BurtgelDans
        hurunguData={selectedHurungu}
        closeDrawer={onCancelBurtgel}
        open={burtgel}
      />
      <Drawer
        title="Өртгийн өөрчлөлтийн мэдээлэл"
        open={visibleDahinAll}
        width="90%"
        footer={null}
        closable
        maskClosable={false}
        onClose={onCancelDahinUnelgeeAll}
        className="custom-drawer"
      >
        <DahinUnelgeeAll dahinAllVisible={visibleDahinAll} />
      </Drawer>
      <Modal
        className="custom-modal"
        title="Өртгийн өөрчлөлтийн жагсаалт оруулах"
        open={visible?.dahin}
        closable={false}
        maskClosable={false}
        width={500}
        footer={null}
      >
        <NewFileAddDahin
          onCancel={() => onCancel('dahin')}
          after_handleUpload={after_handleUploadDahin}
          typesList={typesList}
        />
      </Modal>
      {sumType === 'dans' ? mainDansTable() : mainTable()}
    </>
  );
};

export default UndsenHurungu;
