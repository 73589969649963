/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import jwt from 'jwt-decode';
import { capitalize } from 'lodash';
import CompanyNoti from 'src/components/common/companyNoti';
import { WS_DISCONNECT } from 'src/core/actions/socket';
import ChatComponent from 'src/components/common/ChatComponent';
import {
  Layout,
  Menu,
  Button,
  Tooltip,
  Dropdown,
  Avatar,
  PageHeader,
  Modal,
  Tabs,
  Breadcrumb,
} from 'antd';
import {
  UserOutlined,
  LeftOutlined,
  RightOutlined,
  WarningOutlined,
  CaretDownOutlined,
  FilePdfTwoTone,
  VideoCameraTwoTone,
  CarryOutOutlined,
} from '@ant-design/icons';

import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { routeList as mainRoute } from 'src/core/routes/Routes';
import imgLogo from 'src/assets/images/newLogo.png';
import ReactCountryFlag from 'react-country-flag';
import CustomTour from 'src/core/layout/hintBar/CustomTour';
import NotificationComponent from 'src/components/common/notification';
import { remove_jwt } from '../actions/jwt_token';
import { set_month } from '../actions/data_date';
import { routeList, bottomRouteList, _menu, settings } from './utils/data';
import HintBar from './hintBar/hintBar';
import Search from './search';
import CommentDrawer from './comment';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;
const _routeList = [...routeList, ...bottomRouteList];
const _rootSubmenuKeys = [...Array(11).keys()].map((i) => `sub${i + 1}`);

const { TabPane } = Tabs;

const noBackList = [
  '/main/hoishlogdsonTatvar/taxAvgPer',
  '/main/burtgelteiTuhuurumj',
  '/main/ehniiUldegdel/dundajHuvi',
];

const commentDict = {
  '/main/tailan/tatvarTailan': 'tt02',
  '/main/tailan/tatvarTailan/hm02_1': 'tt02',
  '/main/tailan/tatvarTailan/hm02_2': 'tt02',
  '/main/tailan/tatvarTailan/hm02_3a': 'tt02',
  '/main/tailan/tatvarTailan/hm02_3b': 'tt02',
  '/main/tailan/tatvarTailan/hm02_3v': 'tt02',
  '/main/tailan/tatvarTailan/hm02_4': 'tt02',
  '/main/tailan/tatvarTailan/hm02_5': 'tt02',
  '/main/tailan/tatvarTailan/hm02_6': 'tt02',
  '/main/tailan/tatvarTailan/hm02_7': 'tt02',
  '/main/tailan/tatvarTailan/hm02_8': 'tt02',
  '/main/zuruuTailan/zohitsuulahTailan': 'st30',
};

const MainLayout = (props) => {
  // global variables
  const sliderRef = useRef();
  const dispatch = useDispatch();
  // eslint-disable-next-line operator-linebreak
  const { version, data_date, jwt_token, codeList, open_search, socket } =
    useSelector((state) => ({
      version: state.version,
      data_date: state.data_date,
      jwt_token: state.jwt_token,
      codeList: state.role,
      open_search: state.open_search,
      socket: state.socket,
    }));

  const history = useHistory();
  const [language, setLanguage] = useState(1);
  const [index, seTindex] = useState(0);
  const { t, i18n } = useTranslation();
  // const { t } = useTranslation();

  // main states
  const [companyName, seTcompanyName] = useState('');
  const [userName, seTuserName] = useState('');

  const [breadList, seTbreadList] = useState([
    {
      title: 'Нүүр',
      to: '/main',
    },
  ]);

  const [collapse, seTcollapse] = useState(false);
  const [openKeys, setOpenKeys] = useState(['']);
  const [selectedKeys, seTselectedKeys] = useState('');
  const [visible, seTvisible] = useState(false);
  const [activeItem, setActiveItem] = useState('1');
  const [radioValue, seTradioValue] = useState('first');
  const [tabs, seTtabs] = useState([]);
  const [btnDisable, seTbtnDisable] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);

  const configureTabs = () => {
    const routeListMain = Object.values(mainRoute);
    const findedArr = routeListMain.find((i) => i.path === props?.pathName);
    if (findedArr) {
      const { children = [], ...other } = findedArr;
      if (children.length === 0) {
        seTtabs([]);
      } else {
        seTtabs(
          [other, ...children].map((j, idx) => {
            const { component, ..._j } = j;
            return { key: (idx + 1).toString(), ..._j };
          })
        );
        setActiveItem('1');
      }
    } else {
      Object.values(mainRoute).forEach((j) => {
        const { children = [] } = j;
        if (children.length > 0) {
          const _child = children.find((i) => i.path === props?.pathName);
          const _idx = children.findIndex((i) => i.path === props?.pathName);
          if (_child) {
            setActiveItem((_idx + 2).toString());
            seTtabs(
              [j, ...children].map((f, idx) => {
                const { component, ..._j } = f;
                return { key: (idx + 1).toString(), ..._j };
              })
            );
          }
        }
      });
    }
  };

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      const findedTabs = tabs.find(
        (i, idx) => idx !== 0 && i?.path === props?.pathName
      );
      if (findedTabs) {
        const temp = breadList;
        seTbreadList([
          ...temp,
          { title: findedTabs?.title, to: findedTabs?.path },
        ]);
      }
    }
  }, [tabs]);

  useEffect(() => {
    let _nodeId = '';
    let _id = '';
    const { jwt_1 } = jwt_token;
    const { company_name = '', user_name = '' } = jwt(jwt_1) || {};
    seTuserName(user_name);
    seTcompanyName(company_name);

    if (props?.pathName) {
      configureTabs();
      _routeList.forEach((i) => {
        const { children, title } = i;
        const child = children.find((j) => {
          if (j?.url === props?.pathName || props?.pathName.includes(j?.url)) {
            return true;
          }
          return false;
        });
        if (child) {
          _nodeId = child?.nodeId;
          _id = i?.key;
          const _parent = { title, to: children[0].url };
          const _child = { title: child?.text, to: child?.url };
          seTbreadList([_parent, _child]);
        }
      });
      if (_nodeId) {
        setOpenKeys([_id]);
        seTselectedKeys(_nodeId);
        if (parseInt(_nodeId, 10) > 50) {
          seTradioValue('second');
        }
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [props?.pathName]);

  useEffect(() => {
    configureTabs();
    // eslint-disable-next-line react/destructuring-assignment
  }, [props?.pathName]);

  useEffect(() => {
    if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
      if (version?.is_first_year) {
        seTradioValue('second');
        seTbtnDisable(true);
      } else {
        seTbtnDisable(false);
      }
    }
  }, [version?.is_first_year]);

  useEffect(() => {
    if (collapse) return;
    sliderRef.current.slickGoTo(index);
    seTindex(open_search ? 0 : 1);
  }, [open_search]);

  const ChangeCompany = () => {
    localStorage.removeItem('etaxtoken');
    localStorage.setItem('notifications', JSON.stringify([]));
    socket.emit('send_to_company_select', {
      channel: 'ss002_auth_manage',
      content: {},
      tag: { jwt_1: localStorage.getItem('taxjwt') },
    });
  };

  const changeTabs = (key) => {
    setActiveItem(key);
    const findedData = tabs.find((i) => i.key === key);
    if (findedData) {
      history.push(findedData?.path);
    }
  };

  // date changed call dispatch
  const changeMonth = (date) => {
    dispatch(set_month(date));
    localStorage.setItem('activeMonth', date);
  };

  const onCollapse = () => {
    seTcollapse(!collapse);
    seTindex(1);
  };

  const logout = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    localStorage.removeItem('activeMonth');
    localStorage.removeItem('taxjwt');
    history.push('/login');
    dispatch(WS_DISCONNECT(socket));
    dispatch(remove_jwt());
  };

  const onChangeRadio = ({ target }) => {
    const { value } = target;
    seTradioValue(value);
  };

  const showOutConfirm = () => {
    confirm({
      title: 'Та гарахдаа итгэлтэй байна уу?',
      icon: <WarningOutlined />,
      okText: 'Тийм',
      okType: 'danger',
      cancelText: 'Үгүй',
      onOk() {
        logout();
      },
      onCancel() {},
    });
  };

  // month list menu
  const menu = (
    <Menu className="p-5">
      {_menu.map((item) => (
        <Menu.Item
          style={{
            padding: 0,
          }}
        >
          {item.map((j) => (
            <Button
              type="text"
              className="w-80 fw-600"
              onClick={() => {
                changeMonth(j);
                seTvisible(!visible);
              }}
              block
            >
              {t(`mainLayout.calendar.${j}`, `${j}-р сар`)}
            </Button>
          ))}
        </Menu.Item>
      ))}
    </Menu>
  );

  const toUser = () => {
    history.push('/main/hereglegchMedeelel');
  };

  const changeLanguage = () => {
    const lan = language === 2 ? 1 : 2;
    i18n.changeLanguage(lan === 2 ? 'en' : 'mn');
    setLanguage(lan);
  };

  // logout dropDown menu
  const menuLogout = (
    <Menu>
      <Menu.Item className="custom-menu-item">{userName || ''}</Menu.Item>
      <Menu.Item className="custom-menu-item" onClick={toUser}>
        {t('mainLayout.userEdit', 'Хэрэглэгчийн мэдээлэл')}
      </Menu.Item>
      <Menu.Item className="custom-menu-item" onClick={ChangeCompany}>
        {t('mainLayout.ChangeCompany', 'Компани солих')}
      </Menu.Item>
      <Menu.Item className="custom-menu-item" onClick={() => changeLanguage()}>
        <span className="name-text">
          {language === 2 ? 'English' : 'Монгол'}
          <ReactCountryFlag
            countryCode={language === 2 ? 'GB' : 'MN'}
            style={{ marginLeft: 10, fontSize: 18 }}
            // className="ml-10 fs-20"
            svg
          />
        </span>
      </Menu.Item>
      <Menu.Item className="custom-menu-item bottom" onClick={showOutConfirm}>
        {t('mainLayout.logOut', 'Системээс гарах')}
      </Menu.Item>
    </Menu>
  );

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (_rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const comingSoon = (data) => (
    <Tooltip
      placement="rightTop"
      title="Тун удахгүй"
      key="leftButton"
      color="cyan"
    >
      <Menu.Item
        style={{
          color: '#464a4b',
          fontSize: 12,
        }}
        key={data.nodeId}
      >
        {data?.text}
      </Menu.Item>
    </Tooltip>
  );

  const subItem = (data) => (
    <Menu.Item key={data.nodeId}>
      {data?.text.length > 27 ? (
        <Tooltip
          placement="rightTop"
          title={t(`sideMenu.${data?.tText}`, data?.text)}
          key="leftButton"
        >
          <Link to={data.url}>{t(`sideMenu.${data?.tText}`, data?.text)}</Link>
        </Tooltip>
      ) : (
        <Link to={data.url}> {t(`sideMenu.${data?.tText}`, data?.text)}</Link>
      )}
    </Menu.Item>
  );

  const mainSubItem = (data) => {
    const isPermited = codeList.find((i) => i?.code === data?.code);
    if (data?.code) {
      if (isPermited) {
        return subItem(data);
      }
    } else {
      return subItem(data);
    }
    return null;
  };

  const breadCrumb = () => (
    <>
      <Breadcrumb className="inside-bread fs-12" separator=">">
        {breadList.map((i) => (
          <Breadcrumb.Item>
            <Link to={i?.to}>{i.title}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </>
  );

  const openComment = () => {
    if (!commentOpen) {
      setCommentOpen(true);
    }
  };

  const closeComment = () => {
    if (commentOpen) {
      setCommentOpen(false);
    }
  };

  const commentButtonVisible = (path) => {
    if (path in commentDict) {
      return false;
    }
    return true;
  };

  const getTailanType = (path) => {
    if (path in commentDict) {
      return commentDict[path];
    }
    return '';
  };

  return (
    <div className="App">
      <CompanyNoti />
      <Layout className="main-layout">
        <Sider
          className={`left-sider-layout ${collapse ? 'open' : 'closed'}`}
          breakpoint="lg"
          collapsedWidth="0"
          collapsed={collapse}
          trigger={null}
        >
          <div className={`icon-container ${collapse && 'collapsed'}`}>
            {collapse && (
              <RightOutlined
                className="t-5 fs-20 p-12 pb-5 ml-3 mb-3"
                onClick={onCollapse}
              />
            )}
            {!collapse && (
              <>
                <a href="/">
                  <img src={imgLogo} alt="Logo" />
                </a>
                <LeftOutlined className="open-icon" onClick={onCollapse} />
              </>
            )}
          </div>
          <Slider
            className={`searcher ${collapse ? 'open' : 'closed'}`}
            {...settings}
            ref={sliderRef}
          >
            {' '}
            <Menu
              className={`ant-left-menu  ${collapse ? 'closed' : ''}`}
              mode="inline"
              openKeys={openKeys}
              selectedKeys={selectedKeys}
              onClick={(e) => {
                seTselectedKeys(e.key);
              }}
              inlineCollapsed={collapse}
              onOpenChange={onOpenChange}
            >
              {radioValue === 'first'
                ? routeList.map((item) => (
                    // eslint-disable-next-line react/jsx-indent
                    <SubMenu
                      key={item?.key}
                      icon={item?.icon || <UserOutlined />}
                      title={t(`sideMenu.${item?.tText}`, item?.title)}
                    >
                      {item.children.map((inner_item) => {
                        if (!inner_item?.url) {
                          return comingSoon(inner_item);
                        }
                        return mainSubItem(inner_item);
                      })}
                    </SubMenu>
                    // eslint-disable-next-line indent
                  ))
                : bottomRouteList.map((item) => (
                    // eslint-disable-next-line react/jsx-indent
                    <SubMenu
                      key={item?.key}
                      icon={item?.icon || <UserOutlined />}
                      title={t(`sideMenu.${item?.tText}`, item?.title)}
                    >
                      {item.children.map((element) => {
                        if (!element?.url) {
                          return comingSoon(element);
                        }
                        return mainSubItem(element);
                      })}
                    </SubMenu>
                    // eslint-disable-next-line indent
                  ))}
            </Menu>
            {!collapse && <Search hide={collapse} />}
          </Slider>
          {!collapse && (
            <div className="middle">
              <label>
                <input
                  type="radio"
                  name="radio"
                  disabled={btnDisable}
                  checked={radioValue === 'first'}
                  onChange={onChangeRadio}
                  value="first"
                />
                <div className="front-end box">
                  <span>{t('mainLayout.mainSlider', 'Үндсэн цэс')}</span>
                </div>
              </label>
              <label>
                <input
                  type="radio"
                  name="radio"
                  value="second"
                  checked={radioValue === 'second'}
                  onChange={onChangeRadio}
                />
                <div className="back-end box">
                  <span>{t('mainLayout.settingSlider', 'Тохиргоо')}</span>
                </div>
              </label>
            </div>
          )}
        </Sider>
        <Layout className="main-color">
          <Header className="header-layout">
            <PageHeader
              ghost={false}
              className="w-100 h-60 p-0 background-trans color-layout"
              title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="header-container">
                  <Tooltip
                    title={capitalize(companyName) || 'Бүртгэлгүй компани'}
                  >
                    <p className="mr-15 company-title">
                      {capitalize(companyName) || 'Бүртгэлгүй компани'}
                    </p>
                  </Tooltip>
                  {breadCrumb()}
                </div>
              }
              extra={[
                <span className="fs-14 fw-600 ml-10 mr-0">
                  {version?.is_first_year
                    ? t('mainLayout.firstYear', 'Эхний үлдэгдлийн жил')
                    : t('mainLayout.tailantJil', 'Тайлангийн жил')}
                  : {version?.year_end}
                </span>,
                <RightOutlined className="header-icon" />,
                <span className="fs-14 fw-600 ml-10 mr-0 header-dropdown">
                  {t('mainLayout.tailantUe', 'Тайлант үе')}:
                </span>,
                <Dropdown
                  placement="bottomRight"
                  open={visible}
                  overlay={menu}
                  className="header-dropdown"
                >
                  <Button
                    onClick={() => {
                      seTvisible(!visible);
                    }}
                    type="text"
                    className="m-0 p-5 fs-14 fw-600 color-layout"
                  >
                    <CaretDownOutlined className="fs-12" />
                    {t(
                      `mainLayout.calendar.${data_date?.month}`,
                      `${data_date?.month} сар`
                    )}
                  </Button>
                </Dropdown>,
                <NotificationComponent />,
                <Tooltip placement="bottomLeft" title="Видео заавар">
                  <a
                    href="https://www.youtube.com/@taxacc2694/playlists"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <VideoCameraTwoTone
                      className="header-guidebook-download"
                      twoToneColor="#0094bc"
                    />
                  </a>
                </Tooltip>,
                <Tooltip
                  placement="bottomLeft"
                  title="Хэрэглэгчийн гарын авлага"
                >
                  <a
                    href="https://drive.google.com/file/d/14AZG79wtkzsjfk_YVt-sKCFYUnOv133W"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FilePdfTwoTone
                      className="header-guidebook-download"
                      twoToneColor="#0094bc"
                    />
                  </a>
                </Tooltip>,
                <Dropdown
                  placement="bottomRight"
                  overlay={menuLogout}
                  trigger={['click']}
                >
                  <Avatar className="custom-avatar">
                    {/* {companyName.toUpperCase()[0]} */}
                    <UserOutlined />
                  </Avatar>
                </Dropdown>,
              ]}
            />
          </Header>
          <Content className="main-content-layout">
            <main
              className={`mainContent ${
                // eslint-disable-next-line react/destructuring-assignment
                noBackList.includes(props?.pathName) ? 'no-background' : ''
              } `}
            >
              <div className="layout-tabs">
                {tabs.length > 0 ? (
                  <Tabs activeKey={activeItem} onChange={changeTabs}>
                    {tabs.map((item) => (
                      <TabPane
                        tab={t(item?.tTitle || '---', item?.title)}
                        key={item?.key}
                      />
                    ))}
                  </Tabs>
                ) : (
                  <div
                    style={{
                      height: 20,
                    }}
                  />
                )}
              </div>
              <div
                className={`main-body ${tabs.length === 0 ? 'no-scroll' : ''}`}
                id="modalMount"
              >
                {props?.children}
                <HintBar location={props?.pathName} />
                <ChatComponent />
                <Button
                  className="main-body-comment-toggle"
                  onClick={() => openComment()}
                  hidden={commentButtonVisible(props?.pathName)}
                >
                  <CarryOutOutlined />
                  ТМЗ зөвлөмж
                </Button>
                <CommentDrawer
                  open={commentOpen}
                  closeDrawer={() => closeComment()}
                  pageType={getTailanType(props?.pathName)}
                />
              </div>
            </main>
          </Content>
        </Layout>
      </Layout>
      <CustomTour location={props?.pathName} />
    </div>
  );
};

export default MainLayout;
