import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Typography,
  Checkbox,
  Divider,
  InputNumber,
  Select,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { onlyNumber, roundFloat } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import DatePicker from 'src/components/common/datePicker';
import newAlert from 'src/components/newAlert';

const { Text } = Typography;

const UpdateBurtgel = ({ burtgelData = {}, onCancel = {}, visible }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const socket = useSelector((state) => state.socket);

  const [usenorm, seTusenorm] = useState(false);
  const [notUseEdit, seTnotUseEdit] = useState(false);
  const [listDans, seTlistDans] = useState(null);

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      // console.log(data);
      if (data.status === '0') {
        seTlistDans(data?.content.dans_list);
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        action_type: 'get_tur_zuruu_turees',
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        burtgel_dugaar: burtgelData?.burtgel_dugaar,
        tat_urtug_anh: roundFloat(burtgelData?.tat_urtug_anh),
        urtug_anh: roundFloat(burtgelData?.urtug_anh),
        ashiglaj_duusah: burtgelData?.ashiglaj_duusah,

        tat_ashiglaj_ehelsen: burtgelData?.ashiglaj_ehelsen,
        tat_edited_ashiglaj_ehelsen: burtgelData?.edited_ashiglaj_ehelsen
          ? burtgelData?.tat_edited_ashiglaj_ehelsen
          : burtgelData?.tat_ashiglaj_ehelsen,

        tat_ashiglah_norm: burtgelData?.tat_ashiglah_norm,
        tat_edited_ashiglah_norm: burtgelData?.tat_edited_ashiglah_norm
          ? burtgelData?.tat_edited_ashiglah_norm
          : burtgelData?.tat_ashiglah_norm,

        tat_ashiglaj_duusah: burtgelData?.tat_ashiglaj_duusah,
        turees_uglug_dans: burtgelData?.turees_uglug_dans
          ? burtgelData?.turees_uglug_dans
          : null,
        tat_edited_ashiglaj_duusah: burtgelData?.tat_edited_ashiglaj_duusah
          ? burtgelData?.tat_edited_ashiglaj_duusah
          : burtgelData?.tat_ashiglaj_duusah,
      });
      seTnotUseEdit(burtgelData?.tat_not_use_edit);
      if (burtgelData?.hurungu_buleg_dugaar === 6) {
        select_data();
      }
    }
  }, [burtgelData]);

  const handleSave = () => {
    const new_data_temp = {
      ...form.getFieldsValue(),
      use_norm: usenorm,
      burtgel_dugaar: burtgelData?.burtgel_dugaar,
      not_use_edit: notUseEdit,
    };
    if (
      // eslint-disable-next-line operator-linebreak
      new_data_temp?.tat_edited_ashiglaj_duusah <
        // eslint-disable-next-line operator-linebreak
        new_data_temp?.tat_edited_ashiglaj_ehelsen &&
      // eslint-disable-next-line operator-linebreak
      !usenorm &&
      !notUseEdit
    ) {
      newAlert({
        type: 'error',
        msg: 'Ашиглаж дуусах огноо ашиглаж эхэлсэн огноо бага байна',
      });
    } else {
      socket.on('request_out', (data) => {
        socket.removeAllListeners('request_out');
        if (data.status === '0') {
          newAlert({ type: 'success', msg: data?.message });
        } else {
          newAlert({ type: 'error', msg: data?.message });
        }
        onCancel();
        dispatch(end_loading({}));
      });
      socket.emit('request_in', {
        channel: 'ts003_modify',
        content: {
          action_type: 'undsen_medeelel_burtgel',
          json_array: { ...new_data_temp },
        },
      });
      dispatch(start_loading({}));
    }
  };

  const onFinish = () => {
    handleSave();
  };

  const layout = {
    labelCol: {
      xs: { span: 9 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 15 },
      sm: { span: 15 },
    },
  };

  return (
    <div>
      <Modal
        className="custom-modal"
        title={`Бүртгэлийн дугаар : ${burtgelData?.burtgel_dugaar || '---'}`}
        open={visible}
        closable={false}
        maskClosable={false}
        width={600}
        footer={null}
      >
        <div className="dans-medeelel-container">
          <Form
            form={form}
            onFinish={onFinish}
            className="register-form"
            {...layout}
            size="small"
            initialValues={{ edited_ashiglaj_duusah: null }}
          >
            <Divider plain>Санхүү</Divider>
            <Form.Item
              name="ashiglaj_duusah"
              label="Ашиглаж дуусах огноо"
              labelAlign="left"
              rules={[{ required: true, message: '' }]}
            >
              <DatePicker customClass="ww-100 h-25" />
            </Form.Item>
            <Form.Item name="urtug_anh" label="Анхны өртөг" labelAlign="left">
              <InputNumber
                min={0.0}
                className="w-100"
                disabled={Number(burtgelData?.deb_too_shirheg) < 1}
              />
            </Form.Item>
            <Divider plain>Татвар</Divider>
            <Form.Item
              name="tat_urtug_anh"
              label="Анхны өртөг"
              labelAlign="left"
              normalize={onlyNumber}
              rules={[{ required: true, message: '' }]}
            >
              <InputNumber min={0.0} className="w-100" />
            </Form.Item>
            <Form.Item
              name="turees_uglug_dans"
              label="Түрээсийн өглөгийн данс"
              labelAlign="left"
              hidden={burtgelData?.hurungu_buleg_dugaar !== 6}
              // normalize={onlyNumber}
            >
              <Select placeholder="Сонгох" className="w-100" showSearch allowClear defaultValue={null}>
                <Select.Option value={null}>---</Select.Option>
                {listDans
                  && listDans.map((value) => (
                    <Select.Option value={value.dugaar}>{value.dugaar} : {value.ner}</Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Divider plain>
              {' '}
              <Checkbox
                checked={notUseEdit}
                onChange={() => {
                  seTnotUseEdit(!notUseEdit);
                }}
              />{' '}
              Тооцоолсон
            </Divider>
            <Form.Item
              name="tat_ashiglah_norm"
              label="Ашиглаx норм"
              labelAlign="left"
              rules={[
                {
                  required: [4, 6].includes(burtgelData?.hurungu_buleg_dugaar) && notUseEdit,
                  message: '',
                },
              ]}
            >
              {[4, 6].includes(burtgelData?.hurungu_buleg_dugaar) ? (
                <InputNumber min={0.0} className="w-100" />
              ) : (
                <Text>{burtgelData?.tat_ashiglah_norm}</Text>
              )}
            </Form.Item>
            <Form.Item
              name="tat_ashiglaj_ehelsen"
              label="Ашиглаж эхэлсэн огноо"
              labelAlign="left"
            >
              <Text>{burtgelData?.tat_ashiglaj_ehelsen}</Text>
            </Form.Item>
            <Form.Item
              name="tat_ashiglaj_duusah"
              label="Ашиглаж дуусах огноо"
              labelAlign="left"
            >
              <Text>{burtgelData?.tat_ashiglaj_duusah}</Text>
            </Form.Item>
            <Divider plain>
              {' '}
              <Checkbox
                checked={!notUseEdit}
                onChange={() => {
                  seTnotUseEdit(!notUseEdit);
                }}
              />{' '}
              Өөрчилсөн
            </Divider>
            <Form.Item label="Ашиглаx норм өөрчлөх" labelAlign="left">
              <Checkbox
                onChange={(e) => {
                  seTusenorm(e.target.checked);
                }}
              />
            </Form.Item>
            <Form.Item
              name="tat_edited_ashiglah_norm"
              label="Ашиглаx норм"
              labelAlign="left"
              rules={[
                {
                  required: usenorm && !notUseEdit,
                  message: '',
                },
              ]}
            >
              <Input disabled={!usenorm} className="w-100" />
            </Form.Item>
            <Form.Item
              name="tat_edited_ashiglaj_ehelsen"
              label="Ашиглаж эхэлсэн огноо"
              labelAlign="left"
            >
              <DatePicker customClass="ww-100 h-25" />
            </Form.Item>
            <Form.Item
              name="tat_edited_ashiglaj_duusah"
              label="Ашиглаж дуусах огноо"
              labelAlign="left"
              rules={[
                {
                  required: !usenorm && !notUseEdit,
                  message: '',
                },
              ]}
            >
              <DatePicker disable={usenorm} customClass="ww-100 h-25" />
            </Form.Item>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => {
                    onCancel();
                  }}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Буцах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateBurtgel;
