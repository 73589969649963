import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import newAlert from 'src/components/newAlert';
import { CalculatorOutlined } from '@ant-design/icons';

import { Button, Table as AntTable } from 'antd';

import { roundFloat, useQuery } from 'src/core/utils/formater';
import { start_loading, end_loading } from 'src/core/actions/loader';
import Table from 'src/components/common/table';
import { dans_columns } from '../data/dataStructure';

const TabDans = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const version = useSelector((state) => state.version);

  const [tableData, setTableData] = useState([]);
  const [avlagaSum, setAvlagaSum] = useState({});
  const [uglugSum, setUglugSum] = useState({});

  const handleSelectDans = (dans_code, tur_zuruu_turul) => {
    const propName = 'dans_code';
    const propName2 = 'tab';
    const propName3 = 'tur_zuruu_turul';
    const proplist = ['Hariltsagch', 'page2', 'size2', 'page3', 'size3'];
    if (query.get(propName)) {
      query.set(propName, dans_code);
    } else {
      query.append(propName, dans_code);
    }
    if (query.get(propName2)) {
      query.set(propName2, '2');
    } else {
      query.append(propName2, '2');
    }
    if (query.get(propName3)) {
      query.set(propName3, tur_zuruu_turul);
    } else {
      query.append(propName3, tur_zuruu_turul);
    }
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
      }
    });
    history.push({ search: query.toString() });
  };

  const fetchData = async (page, size) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.content.status === '0') {
        setTableData(data.content?.json_array);
        setAvlagaSum(data.content?.avlaga_sum);
        setUglugSum(data.content?.uglug_sum);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_turul',
      content: {
        month: data_date.month,
        receive_channel: 'ts002_00_tatvar_suuri',
        action_type: 'get_list_journal_type',
        data_type: '1',
        dans_only: true,
        return_action_type: 'tatvar_suuri_dansaar',
        page: page || 1,
        size: size || 20,
      },
    });
    dispatch(start_loading({}));
  };

  useEffect(() => {
    const page = query.get('page');
    const size = query.get('size');

    if (query.get('tab')) {
      if (query.get('tab') === '1') {
        fetchData(page, size);
      }
    } else fetchData(page, size);
  }, [query, data_date]);

  const handleCalculateTC = () => {
    socket.on('request_out', (data) => {
      if (data.content.status === '0') {
        socket.removeAllListeners('request_out');

        setTableData(data.content?.json_array);
        setAvlagaSum(data.content?.avlaga_sum);
        setUglugSum(data.content?.uglug_sum);

        newAlert({
          type: 'success',
          msg: 'Татварын суурь амжилттай тооцоологдлоо',
        });
        dispatch(end_loading({}));
      } else if (data.content.status === '1') {
        socket.removeAllListeners('request_out');

        newAlert({ type: 'error', msg: data.content.msg });
        dispatch(end_loading({}));
      } else if (data.content.status === '2') {
        newAlert({ type: 'warning', msg: data.content.msg });
      } else {
        socket.removeAllListeners('request_out');

        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'calculate_tc',
      content: {
        action_type: 'calculate_tc',
        month: data_date.month,
        year: version.year,
      },
    });
    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <Button
        id="first"
        className="add-btn"
        type="primary"
        size="small"
        icon={<CalculatorOutlined />}
        onClick={handleCalculateTC}
      >
        Татварын суурь тооцоолох
      </Button>
    </>
  );

  const showSum = () => (
    <AntTable.Summary fixed>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          Авлагын нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(avlagaSum.ashig || 0)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(avlagaSum.aldagdal || 0)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.eh_uld)}</p>
          <p>{roundFloat(avlagaSum.eh_uld_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.deb)}</p>
          <p>{roundFloat(avlagaSum.deb_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.cred)}</p>
          <p>{roundFloat(avlagaSum.cred_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(avlagaSum.ets_uld)}</p>
          <p>{roundFloat(avlagaSum.ets_uld_valiut)}</p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
      <AntTable.Summary.Row>
        <AntTable.Summary.Cell />
        <AntTable.Summary.Cell align="right">
          Өглөгийн нийт дүн:
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(uglugSum.ashig || 0)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          {roundFloat(uglugSum.aldagdal || 0)}
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.eh_uld)}</p>
          <p>{roundFloat(uglugSum.eh_uld_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.deb)}</p>
          <p>{roundFloat(uglugSum.deb_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.cred)}</p>
          <p>{roundFloat(uglugSum.cred_valiut)}</p>
        </AntTable.Summary.Cell>
        <AntTable.Summary.Cell align="right">
          <p>{roundFloat(uglugSum.ets_uld)}</p>
          <p>{roundFloat(uglugSum.ets_uld_valiut)}</p>
        </AntTable.Summary.Cell>
      </AntTable.Summary.Row>
    </AntTable.Summary>
  );

  return (
    <div className="table-wrapper">
      <div className="inside-table no-scroll">
        <Table
          columns={dans_columns({ handleSelectDans })}
          dataSource={tableData}
          scroll={{
            y: 'calc(100vh - 340px)',
          }}
          title={title}
          customize={showSum}
        />
      </div>
    </div>
  );
};

export default TabDans;
