import React from 'react';
import {
  DatabaseOutlined,
  DollarOutlined,
  ProfileOutlined,
  ControlOutlined,
  WalletOutlined,
  AuditOutlined,
  FileDoneOutlined,
  SettingOutlined,
} from '@ant-design/icons';

export const routeList = [
  {
    key: 'sub1',
    title: 'Өгөгдөл оруулах',
    tText: 'ogogdolOruulah',
    icon: <DatabaseOutlined className="fs-20" />,
    children: [
      {
        key: 'sub1-1',
        text: 'Гүйлгээний жагсаалт',
        tText: 'guilgeeJagsaalt',
        icon: 'Forums',
        url: '/main/ugugdulOruulah/jagsaalt',
        nodeId: '1',
      },
      {
        key: 'sub1-2',
        text: 'Зардлын хязгаарын өгөгдөл',
        tText: 'zardalHyzgaarUgugdul',
        icon: 'Forums',
        url: '/main/ugugdulOruulah/hyazgaar',
        nodeId: '2',
        code: '004_1',
      },
    ],
  },
  {
    key: 'sub2',
    title: 'Дансны үнэ',
    tText: 'dansUne',
    icon: <DollarOutlined className="fs-20" />,
    children: [
      {
        key: 'sub2-1',
        text: 'Гүйлгээ баланс',
        tText: 'guilgeeBalance',
        icon: 'Forums',
        url: '/main/dansniiUne/guilgeeBalance',
        nodeId: '3',
      },
      {
        key: 'sub2-2',
        text: 'Авлага өглөгийн товчоо',
        tText: 'avlagaUglug',
        icon: 'Forums',
        url: '/main/dansniiUne/avlagaUglugTovchoo',
        nodeId: '4',
      },
      {
        key: 'sub2-3',
        text: 'Үндсэн хөрөнгө товчоо',
        tText: 'undsenHurungu',
        icon: 'Forums',
        url: '/main/dansniiUne/undsenHurunguTovchoo',
        nodeId: '5',
      },
    ],
  },
  {
    key: 'sub3',
    title: 'Татварын суурь',
    tText: 'tatvarSuuri',
    icon: <ProfileOutlined className="fs-20" />,
    children: [
      {
        key: 'sub3-1',
        text: 'ТС-Гүйлгээ баланс',
        tText: 'tcGuilgeeBalance',
        icon: 'Forums',
        url: '/main/tatvarSuuri/guilgeeBalance',
        nodeId: '6',
      },
      {
        key: 'sub3-2',
        text: 'ТС-Авлага, өглөгийн товчоо',
        tText: 'tcAvlagaUglug',
        icon: 'Forums',
        url: '/main/tatvarSuuri/avlagaUglugTovchoo',
        nodeId: '7',
      },
      {
        key: 'sub3-3',
        text: 'ТС-Үндсэн хөрөнгийн товчоо',
        tText: 'tcUndsenHurungu',
        icon: 'Forums',
        url: '/main/tatvarSuuri/undsenHurungu',
        nodeId: '8',
      },
    ],
  },
  {
    key: 'sub4',
    title: 'Татварын тохиргоо',
    tText: 'tatvarTohirgoo',
    icon: <ControlOutlined className="fs-20" />,
    children: [
      {
        key: 'sub4-1',
        text: 'ДҮ-ТС Зөрүү',
        tText: 'tcZuruu',
        icon: 'Forums',
        url: '/main/tatvarTohirgoo/dansniiUneZuruu',
        nodeId: '9',
      },
      {
        key: 'sub4-2',
        text: 'Татварын шалгах баланс',
        tText: 'tatvarShalgahBalance',
        icon: 'Forums',
        url: '/main/tatvarTohirgoo/shalgahBalance',
        nodeId: '10',
      },
      {
        key: 'sub4-3',
        text: 'Зардлын хязгаар',
        tText: 'zardalHyzgaar',
        icon: 'Forums',
        url: '/main/tatvarTohirgoo/hyazgaar',
        nodeId: '11',
        code: '004_1',
      },
      {
        key: 'sub4-4',
        text: 'Хөнгөлөлт',
        tText: 'hungulult',
        icon: 'Forums',
        url: '/main/tatvarTohirgoo/hungulult',
        nodeId: '12',
      },
    ],
  },
  {
    key: 'sub5',
    title: 'Хойшлогдсон татвар',
    tText: 'hoishlogdsonTatvar',
    icon: <WalletOutlined className="fs-20" />,
    children: [
      {
        key: 'sub5-1',
        text: 'Түр зөрүү хүлээн зөвшөөрөх',
        tText: 'turZuruu',
        icon: 'Forums',
        url: '/main/hoishlogdsonTatvar/turZuruu',
        nodeId: '13',
      },
      {
        key: 'sub5-2',
        text: 'Дундаж хувь хэмжээ',
        tText: 'dundajHemjee',
        icon: 'Forums',
        url: '/main/hoishlogdsonTatvar/taxAvgPer',
        nodeId: '14',
      },
      {
        key: 'sub5-3',
        text: 'Хойшлогдсон татварын хөрөнгө',
        tText: 'hTatvarHurungu',
        icon: 'Forums',
        url: '/main/hoishlogdsonTatvar/hurungu',
        nodeId: '15',
      },
      {
        key: 'sub5-4',
        text: 'Хойшлогдсон татварын өр төлбөр',
        tText: 'hTatvarUrTulbur',
        icon: 'Forums',
        url: '/main/hoishlogdsonTatvar/urTulbur',
        nodeId: '16',
      },
      {
        key: 'sub5-5',
        text: 'Алдагдал тооцоолол',
        tText: 'aldagdal',
        icon: 'Forums',
        url: '/main/hoishlogdsonTatvar/aldagdal',
        nodeId: '17',
      },
    ],
  },
  {
    key: 'sub6',
    title: 'Зөрүүгийн тайлан',
    tText: 'zuruuginTailan',
    icon: <FileDoneOutlined className="fs-20" />,
    children: [
      {
        key: 'sub6-1',
        text: 'Ажлын хүснэгт',
        tText: 'ajliinHusnegt',
        icon: 'Forums',
        url: '/main/zuruuTailan/ajliinHusnegt',
        nodeId: '18',
      },
      {
        key: 'sub6-2',
        text: 'Зөрүүг зохицуулах тайлан',
        tText: 'zZohitsulahTailan',
        icon: 'Forums',
        url: '/main/zuruuTailan/zohitsuulahTailan',
        nodeId: '19',
      },
      {
        key: 'sub6-3',
        text: 'XТ-ын дансны хөдөлгөөн',
        tText: 'xtDansHudulguun',
        icon: 'Forums',
        url: '/main/zuruuTailan/hm02_9',
        nodeId: '20',
      },
    ],
  },
  {
    key: 'sub7',
    title: 'ААНОАТ-ын тайлан',
    tText: 'aanoatTailan',
    icon: <AuditOutlined className="fs-20" />,
    children: [
      {
        key: 'sub7-1',
        text: 'ААНОАТ-ын тайлан',
        tText: 'aanoatTailan',
        icon: 'Forums',
        url: '/main/tailan/tatvarTailan',
        nodeId: '21',
      },
      // {
      //   key: 'sub7-2',
      //   text: 'Тодруулга, НББ журнал',
      //   tText: 'tnTailan',
      //   icon: 'Forums',
      //   url: '/main/tailan/tatvarNogduulah',
      //   nodeId: '22',
      // },
      {
        key: 'sub7-3',
        text: 'E-tax',
        tText: 'e_tax',
        icon: 'Forums',
        url: '/main/tailan/e_tax',
        nodeId: '23',
      },
    ],
  },
];

export const bottomRouteList = [
  {
    key: 'sub8',
    title: 'Тохиргоо',
    tText: 'tohirgoo',
    icon: <SettingOutlined className="fs-20" />,
    children: [
      {
        key: 'sub8-1',
        text: 'Татварын жил',
        tText: 'tatvarJil',
        icon: 'Forums',
        url: '/main/tohirgoo/tatvarJil',
        nodeId: '24',
      },
      {
        key: 'sub8-2',
        text: 'Эрхийн тохиргоо',
        tText: 'role',
        icon: 'Forums',
        url: '/main/tohirgoo/roleAdd',
        nodeId: '25',
      },
      {
        key: 'sub8-3',
        text: 'Хэрэглэгчийн тохиргоо',
        tText: 'user',
        icon: 'Forums',
        url: '/main/tohirgoo/userAdd',
        nodeId: '26',
      },
      {
        key: 'sub8-4',
        text: 'Татвар төлөгчийн мэдээлэл',
        tText: 'tulugchMedeelel',
        icon: 'Forums',
        url: '/main/tatvarTulugch/medeelel',
        nodeId: '27',
      },
      // {
      //   key: 'sub8-5',
      //   text: 'Үйлдлийн түүх',
      //   tText: 'audit',
      //   icon: 'Forums',
      //   url: '/main/tohirgoo/auditLog',
      //   nodeId: '28',
      // },
      // {
      //   key: 'sub8-6',
      //   text: 'Системийн лог',
      //   tText: 'syslog',
      //   icon: 'Forums',
      //   url: '/main/tohirgoo/sysLog',
      //   nodeId: '29',
      // },
      // {
      //   key: 'sub8-7',
      //   text: 'ТМЗ мониторинг',
      //   tText: 'tmz',
      //   icon: 'Forums',
      //   url: '/main/tohirgoo/tmzMonitoring',
      //   nodeId: '30',
      // },
    ],
  },
  {
    key: 'sub9',
    title: 'Үндсэн мэдээлэл',
    tText: 'undsenMedeelel',
    icon: <SettingOutlined className="fs-20" />,
    children: [
      {
        key: 'sub9-1',
        text: 'Дансны мэдээлэл',
        tText: 'dansMedeelel',
        icon: 'Forums',
        url: '/main/undsenMedeelel/dans',
        nodeId: '31',
      },
      {
        key: 'sub9-2',
        text: 'Харилцагчийн мэдээлэл',
        tText: 'hariltsagch',
        icon: 'Forums',
        url: '/main/undsenMedeelel/hariltsagch',
        nodeId: '32',
      },
      {
        key: 'sub9-3',
        text: 'Үндсэн хөрөнгийн мэдээлэл',
        tText: 'UndsenHurungu',
        icon: 'Forums',
        url: '/main/undsenMedeelel/hurungu',
        nodeId: '33',
      },
      // {
      //   key: 'sub9-4',
      //   text: 'Татвар-Үндсэн хөрөнгийн мэдээлэл',
      //   tText: 'tatvarMedeelel',
      //   icon: 'Forums',
      //   url: '/main/undsenMedeelel/hurunguTatvar',
      //   nodeId: '34',
      // },
      {
        key: 'sub9-5',
        text: 'Алдагдал бүртгэл',
        tText: 'aldagdalBurtgel',
        icon: 'Forums',
        url: '/main/undsenMedeelel/aldagdalBurtgel',
        nodeId: '35',
      },
    ],
  },
  {
    key: 'sub10',
    title: 'Эхний үлдэгдэл',
    tText: 'ehniiUldegdel',
    icon: <SettingOutlined className="fs-20" />,
    children: [
      {
        key: 'sub10-1',
        text: 'Дансны үнэ',
        tText: 'dansUne',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/dansUne',
        nodeId: '36',
      },
      {
        key: 'sub10-2',
        text: 'Татварын суурь',
        tText: 'tatvarSuuri',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/tatvarSuuri',
        nodeId: '37',
      },
      {
        key: 'sub10-3',
        text: 'Татварын тохиргоо',
        tText: 'tatvarTohirgoo',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/tatvarTohirgoo',
        nodeId: '38',
      },
      {
        key: 'sub10-4',
        text: 'Түр зөрүү хүлээн зөвшөөрөх',
        tText: 'turZuruu',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/turZuruu',
        nodeId: '39',
      },
      {
        key: 'sub10-5',
        text: 'Дундаж хувь хэмжээ',
        tText: 'dundajHemjeeEhnii',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/dundajHuvi',
        nodeId: '40',
      },
      {
        key: 'sub10-6',
        text: 'Хойшлогдсон татварын хөрөнгө',
        tText: 'hTatvarHurungu',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/hoishTatvarHurungu',
        nodeId: '41',
      },
      {
        key: 'sub10-7',
        text: 'Хойшлогдсон татварын өр төлбөр',
        tText: 'hTatvarUrTulbur',
        icon: 'Forums',
        url: '/main/ehniiUldegdel/hoishTatvarUrTulbur',
        nodeId: '42',
      },
    ],
  },
];

export const _menu = [
  [1, 2, 3],
  [4, 5, 6],
  [7, 8, 9],
  [10, 11, 12],
];

export const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  swipe: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
};
