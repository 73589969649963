/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NodeRSA from 'node-rsa';
import { set } from 'lodash';
import Lottie from 'react-lottie';
import logo from 'src/assets/images/noCompany.json';
import {
  Typography,
  Badge,
  List,
  Avatar,
  Input,
  Button,
  Menu,
  Dropdown,
  Modal,
  Row,
  Tooltip,
  Col,
} from 'antd';
import {
  WarningOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { remove_jwt } from 'src/core/actions/jwt_token';
import { set_month } from 'src/core/actions/data_date';
import newAlert from 'src/components/newAlert';
import headerImg from 'src/assets/images/mainLogo.png';
import { useHubspotChat } from 'src/core/layout/hubSpot';
import { WS_DISCONNECT } from 'src/core/actions/socket';
import { SelectModul } from './selectModul';

const { Title } = Typography;
const { confirm } = Modal;
const text = 'Компанид хүсэлт илгээж бүртгэлээ үүсгээрэй';
const colLayout = {
  md: {
    span: 11,
    offset: 1,
  },
  lg: {
    span: 7,
    offset: 1,
  },
};

const CompanyList = () => {
  const [selectedIndex, seTselectedIndex] = useState(null);
  const [companyList, seTcompanyList] = useState([]);
  const [password, seTpassword] = useState('');
  const [userName, seTuserName] = useState('');

  const rsa = useSelector((state) => state.rsa);
  const history = useHistory();
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.socket);
  const jwt_token = useSelector((state) => state.jwt_token);

  const { addWidget } = useHubspotChat(
    process.env.HUBSPOT_PORTAL_ID || 21532402
  );

  useEffect(() => {
    if (jwt_token?.jwt_1) {
      socket.on('request_initial', (data) => {
        socket.removeAllListeners('request_initial');
        const { status, content } = data;
        if (status === '0') {
          const { company_list, user_name } = content;
          seTuserName(user_name);
          seTcompanyList(company_list);
        }
      });
      socket.emit('get_company_list', {
        channel: 'ss004_user_management',
        content: {
          action_type: 'user_get_company_id',
        },
        tag: { jwt_1: jwt_token?.jwt_1, socket_id: socket.id },
      });
    }
  }, []);

  const submit = (values) => {
    const { ..._companyList } = values;
    const companyData = { password, _companyList };
    const key = new NodeRSA({ b: 512 });
    localStorage.setItem('activeMonth', 12);
    dispatch(set_month(12));
    key.importKey(rsa.serverPublicKey, 'pkcs1-public-pem');
    if (password) {
      set(companyData, 'password', key.encrypt(password, 'base64'));
    }
    socket.on('select_company_rsp', (data) => {
      socket.removeAllListeners('select_company_rsp');
      if (data?.status === '0') {
        addWidget();
      } else {
        newAlert({ type: 'error', msg: data?.content?.Message });
      }
    });
    socket.emit('select_company', {
      channel: 'ss004_user_management',
      content: {
        action_type: 'check_company_id',
        ...companyData,
      },
      tag: jwt_token?.jwt_1,
    });
  };

  const changePass = ({ target }) => {
    seTpassword(target?.value);
  };

  const logout = () => {
    document.cookie.split(';').forEach((c) => {
      document.cookie = c
        .replace(/^ +/, '')
        .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
    });
    localStorage.removeItem('taxjwt');
    history.push('/login');
    dispatch(WS_DISCONNECT(socket));
    dispatch(remove_jwt());
  };

  const checker = (item, type) => {
    if (item?.company_otp_state === '0') {
      return type === 'color' ? '#808080' : 'Идэвхгүй';
    }
    return type === 'color' ? '#02c0df' : 'Идэвхтэй';
  };

  const color = (item) => checker(item, 'color');
  const state = (item) => checker(item, 'state');
  const onSet = { color, state };

  const showOutConfirm = () => {
    confirm({
      title: 'Та гарахдаа итгэлтэй байна уу?',
      icon: <WarningOutlined />,
      okText: 'Тийм',
      okType: 'danger',
      cancelText: 'Үгүй',
      onOk() {
        logout();
      },
    });
  };

  const menuLogout = (
    <Menu>
      <Menu.Item className="custom-menu-item bottom" onClick={showOutConfirm}>
        Гaрaх
      </Menu.Item>
    </Menu>
  );

  const listMaker = () => {
    if (!companyList) {
      return (
        <div className="no-company">
          <Lottie
            height={120}
            width={120}
            options={{
              animationData: logo,
            }}
          />
          <span>
            Танд одоогоор бүртгэлтэй компани байхгүй байна
            <Tooltip className="icon" placement="right" title={text}>
              <InfoCircleOutlined />
            </Tooltip>
          </span>
        </div>
      );
    }
    if (companyList && companyList?.length > 0) {
      return (
        <List
          className="custom-list-company-list"
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: companyList.length > 2 ? 3 : 2,
            xxl: companyList.length > 2 ? 3 : 2,
          }}
          dataSource={companyList}
          renderItem={(item, key) => {
            const _color = onSet.color(item, key);
            const _state = onSet.state(item, key);
            return (
              <Badge.Ribbon text={_state} color={_color}>
                <div
                  className="company-card"
                  onClick={() => {
                    if (item?.company_otp_state !== '0') {
                      SelectModul({
                        onOk: () => submit(item),
                      });
                    } else {
                      seTselectedIndex(key);
                    }
                  }}
                >
                  {item?.company_otp_state === '0' && selectedIndex === key ? (
                    <div className="password-section">
                      <Input.Password
                        value={password}
                        onChange={changePass}
                        placeholder="Нууц үгээ оруулна уу?"
                        visibilityToggle={false}
                      />
                      <Button
                        style={{
                          width: 100,
                        }}
                        className="add-btn"
                        type="primary"
                        shape="round"
                        size="small"
                        onClick={() => {
                          SelectModul({
                            onOk: () => submit(item),
                          });
                        }}
                      >
                        Нэвтрэх
                      </Button>
                    </div>
                  ) : (
                    <>
                      <Avatar
                        className="icon"
                        src={item?.logo}
                        size={50}
                        icon={<UserOutlined />}
                      />
                      <div className="card-body">
                        <p className="grey">{item?.reg_num}</p>
                        <p>{item?.company_name}</p>
                        <p className="admin">{item?.role_name}</p>
                      </div>
                    </>
                  )}
                </div>
              </Badge.Ribbon>
            );
          }}
        />
      );
    }
    return (
      <Row className="company-row-sk" justify="center">
        {[...Array(9)].map(() => (
          <Col {...colLayout} className="center">
            <div className="company-card">
              <Avatar size="large" className="skeleton" />
              <div className="card-body with-gap">
                <div className="one-item skeleton" />
                <div className="one-item big skeleton" />
                <div className="one-item big skeleton" />
              </div>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div className="company-list-container">
      <div className="nav-bar">
        <div>
          <img src={headerImg} height={40} />
        </div>
        <div className="account-section">
          <span>{userName || 'Бүртгэлгүй хэрэглэгч'}</span>
          <Dropdown
            overlay={menuLogout}
            placement="bottomRight"
            trigger={['click']}
          >
            <UserOutlined
              style={{ marginLeft: 10, marginRight: 10, fontSize: 18 }}
            />
          </Dropdown>
        </div>
      </div>
      <div className="inside-box">
        <div className="header">
          <Title style={{ marginLeft: 30 }} level={5}>
            Аж ахуй нэгж сонгох
          </Title>
        </div>
        <div className="inside-container">{listMaker()}</div>
      </div>
    </div>
  );
};

export default CompanyList;
