import React, { useState, useEffect } from 'react';
import TreeSelect from 'src/components/common/treeSelect';

import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select as AntSelect,
  Divider,
  Modal,
} from 'antd';
import { get } from 'lodash';
import { currencyList } from '../utils/data';

const layout = {
  labelCol: {
    xs: { span: 12 },
    sm: { span: 7 },
  },
  wrapperCol: {
    xs: { span: 12 },
    sm: { span: 17 },
  },
};

const CustomAdd = ({
  visible = false,
  edit = false,
  form = null,
  onClose = () => {},
  listData = {},
  saveValues = () => {},
}) => {
  const [subList, seTsubList] = useState([]);
  const onFinish = (values) => {
    saveValues(values);
    form.resetFields();
    onClose();
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const startWith = (value) => {
    if (!value || value.length === 0) return 'balance_angilal';
    const _value = parseInt(value.charAt(0), 10);
    return _value > 4 ? 'orlogo_angilal' : 'balance_angilal';
  };

  const checkString = (str) => str.indexOf('--') === -1 && !str.startsWith('-');

  const nameNormalize = (value = '') => {
    const customRegex = /^[0-9-+()]*$/;
    if (value.length > 0) {
      const _value = value.match(customRegex);
      const check = checkString(value);
      return _value && check ? value : value.substring(0, value.length - 1);
    }
    return value;
  };

  const setDansTurul = ({ target }) => {
    const { value } = target;
    const type = startWith(value);
    form.setFieldsValue({
      dans_turul: type === 'orlogo_angilal' ? 'Орлого' : 'Баланс',
    });
  };

  // const setDans = (value) => {
  //   seTsubList(listData?.ded_angilal.filter(
  //     (item) => item.angilal_id === value
  //   ));
  // };

  // useEffect(() => {
  //   if (form.getFieldValue('dans_angilal_id') && visible) {
  //     setDans(form.getFieldValue('dans_angilal_id'));
  //   }
  // }, [visible]);

  // eslint-disable-next-line operator-linebreak
  const { tur_zuruu_turul_list = [], tuslah_journal_list = [] } =
    listData || {};

  return (
    <Modal
      className="custom-modal"
      title="Дансны мэдээлэл засах"
      visible={visible}
      closable={false}
      maskClosable={false}
      width={700}
      footer={null}
    >
      <div className="dans-medeelel-container">
        <Form
          form={form}
          onFinish={onFinish}
          className="register-form"
          {...layout}
          size="small"
        >
          <Form.Item name="dans_id" hidden />
          <Form.Item
            name="dans_dugaar"
            label="Код"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            onChange={setDansTurul}
            normalize={nameNormalize}
          >
            <Input
              disabled={edit}
              className="w-100"
              placeholder="Дансны код бөглөнө үү..."
            />
          </Form.Item>
          <Form.Item
            name="dans_ner"
            label="Нэр"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
              { min: 0, max: 300 },
            ]}
          >
            <Input className="w-100" placeholder="Дансны нэр бөглөнө үү..." />
          </Form.Item>
          <Form.Item
            name="dans_turul"
            label="Төрөл"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <AntSelect defaultValue="Баланс" disabled>
              <AntSelect.Option value="Баланс">Баланс</AntSelect.Option>
              <AntSelect.Option value="Орлого">Орлого</AntSelect.Option>
            </AntSelect>
          </Form.Item>
          <Form.Item
            name="valiut_torol"
            label="Валютын төрөл"
            labelAlign="left"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <AntSelect defaultValue="MNT">
              {currencyList.map((i) => (
                <AntSelect.Option value={i}>{i}</AntSelect.Option>
              ))}
            </AntSelect>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const type = startWith(getFieldValue('dans_dugaar'));
              return (
                <>
                  <Form.Item
                    name="dans_angilal_id"
                    label="Ангилал"
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: '',
                      },
                    ]}
                  >
                    <TreeSelect
                      // onChange={setDans}
                      data={get(listData, `${type}`)}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          {/* <Form.Item
            name="sub_angilal"
            label="Дэд ангилал"
            labelAlign="left"
          >
            <AntSelect defaultValue={null} disabled={!form.getFieldValue('dans_angilal_id')}>
              <AntSelect.Option value={null}>---</AntSelect.Option>
              {subList.map((i) => (
                <AntSelect.Option value={i?.id}>{i?.code} {i?.ner}</AntSelect.Option>
              ))}
            </AntSelect>
          </Form.Item> */}
          <Divider hidden={form.getFieldValue('dans_turul') === 'Орлого'} />
          <Form.Item
            name="tuslah_journal_type"
            label="Туслах журнал"
            labelAlign="left"
            hidden={form.getFieldValue('dans_turul') === 'Орлого'}
          >
            <AntSelect defaultValue={null}>
              <AntSelect.Option value={null}>---</AntSelect.Option>
              {tuslah_journal_list.map((i) => (
                <AntSelect.Option value={i?.id}>{i?.ner}</AntSelect.Option>
              ))}
            </AntSelect>
          </Form.Item>
          <Form.Item
            name="tur_zuruu_turul_id"
            label="Түр зөрүүгийн төрөл"
            labelAlign="left"
            hidden={form.getFieldValue('dans_turul') === 'Орлого'}
          >
            <AntSelect defaultValue={null}>
              <AntSelect.Option value={null}>---</AntSelect.Option>
              {tur_zuruu_turul_list.map((i) => (
                <AntSelect.Option value={i?.id}>{i?.ner}</AntSelect.Option>
              ))}
            </AntSelect>
          </Form.Item>
          <Row>
            <Col span={24} className="text-align-r">
              <Form.Item noStyle>
                <Button
                  onClick={onCancel}
                  className="cancel-btn"
                  type="primary"
                  shape="round"
                >
                  Цуцлах
                </Button>
                <Button
                  className="confirm-btn ml-5"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                >
                  Хадгалах
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
export default CustomAdd;
