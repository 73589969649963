import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { useQuery } from 'src/core/utils/formater';
import { Table, Warning } from 'src/components';
import CanView from 'src/components/permission/canview';
import { Button as AntButton, Modal, Form, Row } from 'antd';
import { start_loading, end_loading } from 'src/core/actions/loader';
import newAlert from 'src/components/newAlert';
import { ColumnsTab1 } from './dataStructure/data';
import NewUpdateHurungu from './newUpdateHurungu';
import NewUpdateDialog from './newUpdateDialog';
import { NewFileAdd, NewFileTovchoo, ChangeHurunguCode } from './newFileAdd';
import { searchList } from './dataStructure/search';
import BurtgelList from './burtgelList';
import { ErrorList } from './ErrorList';

const _columns = ColumnsTab1;

const Tab1 = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const [form] = Form.useForm();

  const socket = useSelector((state) => state.socket);
  const version = useSelector((state) => state.version);

  const [visible, seTvisible] = useState(false);
  const [toggle, seTtoggle] = useState({
    visible1: false,
    visible2: false,
    visible3: false,
    visible4: false,
  });
  const [selectedHurungu, seTselectedHurungu] = useState({});
  const [fileTovchoo, seTfileTovchoo] = useState(false);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: false,
      error_message: [],
    },
  });
  const [errorList, seTerrorList] = useState({
    have_error: false,
    errors: [],
    burtgel_errors: {},
  });
  const [listDef, setlistDef] = useState({ angilal: [] });
  const [visibleHurungu, seTvisibleHurungu] = useState(false);

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const search = {
      hurungu: query.get('hurungu'),
      medeelel: query.get('medeelel') || 'Бүгд',
      // buleg: query.get('buleg') || 'Бүгд',
      // zuruu: query.get('zuruu') || 'Бүгд',
      type: 'uh',
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'undsen_medeelel_page',
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  const select_hurungu_def = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setlistDef(data?.content?.result_json);
        select_data();
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: { action_type: 'undsen_medeelel_def' },
    });
    dispatch(start_loading({}));
  };

  const after_handleUpload = (content) => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: data.message });
      } else {
        if (data?.content?.result_list) {
          seTerrorList({
            have_error: true,
            errors: data?.content?.result_list,
            burtgel_errors: [],
          });
        }
        newAlert({ type: 'error', msg: data.message });
      }
      seTfileTovchoo(false);
      dispatch(end_loading({}));
    });
    seTfileTovchoo(true);
    socket.emit('request_in', {
      channel: 'ts001_02_dans_control',
      content: {
        general_data: {
          action_type: 'c1_tovchoo_file',
          content: { ...content },
        },
        action_type: 'get_dans_dun_list',
        month: 'ets',
        first_year: true,
        receive_channel: 'ts003_calculate',
      },
    });
  };

  useEffect(() => {
    if (!fileTovchoo) {
      let temp = false;
      Object.keys(toggle).forEach((key) => {
        if (toggle[key]) {
          temp = toggle[key];
        }
      });
      if (!temp) {
        select_hurungu_def();
      }
    }
  }, [query, fileTovchoo]);

  const Open = (param) => {
    seTtoggle((prev) => ({ ...prev, [param]: true }));
  };

  const onSelectHurungu = (row) => {
    seTselectedHurungu(row);
    Open('visible3');
  };

  const onEdit = (row) => {
    form.setFieldsValue({ ...row, old_code: row.code });
    Open('visible1');
  };

  const onCancel = (param) => {
    seTtoggle((prev) => ({ ...prev, [param]: false }));
    select_data();
  };

  const onCancelVisible = () => {
    seTvisible(false);
  };

  const onCancelHurungu = () => {
    seTvisibleHurungu(false);
    select_data();
  };

  const title = () => (
    <Row>
      <>
        {!tableData?.validtion_info?.validated && (
          <Warning show listWarning={['Хөрөнгийн мэдээлэл дутуу байна']} />
        )}
      </>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          Open('visible4');
        }}
      >
        Үндсэн хөрөнгийн код шинэчлэх
      </AntButton>
      {version?.is_first_year ? (
        <CanView allowedRole="006_1">
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              seTvisible({
                ...visible,
                towcho: true,
              });
            }}
          >
            Эхний үлдэгдэл оруулах
          </AntButton>
        </CanView>
      ) : (
        <CanView allowedRole="006_1">
          <AntButton
            className="add-btn"
            type="primary"
            size="small"
            icon={<UpCircleOutlined />}
            onClick={() => {
              Open('visible2');
            }}
          >
            Үндсэн хөрөнгийн бүртгэлээрх дэлгэрэнгүй оруулах
          </AntButton>
        </CanView>
      )}
      <CanView allowedRole="007_1">
        <AntButton
          className="add-btn"
          type="primary"
          size="small"
          onClick={() => {
            seTvisibleHurungu(true);
          }}
        >
          Үндсэн хөрөнгийн ангилал /хөрөнгийн кодоор/
        </AntButton>
      </CanView>
    </Row>
  );

  const onCancelError = () => {
    seTerrorList({
      have_error: false,
      errors: [],
      burtgel_errors: {},
    });
  };

  return (
    <>
      <ErrorList onCancel={onCancelError} errorList={errorList} />
      <NewUpdateHurungu
        visible={visibleHurungu}
        form={form}
        listDef={listDef}
        open={visibleHurungu}
        onCancel={onCancelHurungu}
      />
      <BurtgelList
        hurunguData={selectedHurungu}
        closeDrawer={onCancel}
        open={toggle?.visible3}
      />
      <Modal
        className="custom-modal"
        title="Эхний үлдэгдэл оруулах"
        open={visible}
        width={550}
        footer={null}
      >
        <NewFileTovchoo
          onCancel={() => onCancelVisible()}
          after_handleUpload={after_handleUpload}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Үндсэн хөрөнгийн бүртгэлээрх дэлгэрэнгүй оруулах"
        open={toggle?.visible2}
        width={550}
        footer={null}
        onCancel={() => {
          onCancel('visible2');
        }}
      >
        <NewFileAdd
          onCancel={() => {
            onCancel('visible2');
          }}
        />
      </Modal>
      <Modal
        className="custom-modal"
        title="Үндсэн хөрөнгийн код шинэчлэх"
        open={toggle?.visible4}
        width={550}
        footer={null}
        onCancel={() => {
          onCancel('visible4');
        }}
      >
        <ChangeHurunguCode
          onCancel={() => {
            onCancel('visible4');
          }}
        />
      </Modal>
      <NewUpdateDialog
        listDef={listDef}
        form={form}
        onCancel={onCancel}
        open={toggle?.visible1}
      />
      <div className="table-antd-div no-scroll">
        <Table
          isSearch
          searchList={searchList}
          title={title}
          columns={_columns({ onEdit, onSelectHurungu })}
          total={tableData.total_size}
          dataSource={tableData.result_list}
          scroll={{
            y: 'calc(100vh - 330px)',
          }}
        />
      </div>
    </>
  );
};

export default Tab1;
