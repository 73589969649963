import { combineReducers } from 'redux';

import socket from './socket/socket';
import admin from './admin/admin';
import jwt_token from './jwt_token/jwt_token';
import rsa from './rsa/rsa';
import version from './version/version';
import loader from './loader/loader';
import config from './config/config';
import alert from './alert/alert';
import data_date from './data_date/data_date';
import tax_shalgah from './tax_module/shalgah_balance/tax_shalgah';
import role from './role/role';
import user_role from './user_role/user_role';
import hintStep from './hintStep/hintStep';
import force_stop from './hintStep/forceStop';
import is_loading from './hintStep/is_loading';
import notificationType from './notificationType/notificationType';

import is_empty from './hintStep/isEmpty';
import search_field from './searchField';
import open_search from './searchField/openSearch';
import access_token from './jwt_token/access_token';
import notification_count from './notification/addNotification';

export default combineReducers({
  socket,
  admin,
  jwt_token,
  rsa,
  version,
  loader,
  config,
  alert,
  data_date,
  tm_shalgahbalance_taxtohiruulga: tax_shalgah,
  role,
  user_role,
  hintStep,
  force_stop,
  is_empty,
  search_field,
  open_search,
  is_loading,
  access_token,
  notificationType,
  notification_count,
});
