// eslint-disable-next-line import/no-extraneous-dependencies
import { io } from 'socket.io-client';

const initialState = null;

const reducer = (state = initialState, action) => {
  const query_jwt = localStorage.taxjwt ? `token=${localStorage.taxjwt}` : '';

  switch (action.type) {
    case 'WS_CONNECT':
      if (!state) {
        const options = {
          withCredentials: true,
          secure: true,
          reconnection: true,
          reconnectionDelay: 500,
          reconnectionAttempts: 10,
          transports: ['websocket'],
        };
        const socket = io(`${action.payload.ip}?${query_jwt}`, {
          ...options,
          query: { device_token: localStorage.getItem('device_token') },
        });
        return socket;
      }
      return state;

    case 'WS_DISCONNECT':
      if (state !== null) {
        state.disconnect();
      }
      return null;

    default:
      return state;
  }
};

export default reducer;
