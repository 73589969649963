import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CalculatorOutlined, ExportOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'src/core/utils/formater';
import newAlert from 'src/components/newAlert';
import { Button as AntButton } from 'antd';
import CanView from 'src/components/permission/canview';
import { Table } from 'src/components';
import { customConfirm } from 'src/components/common/confirmModal';
import axios from 'src/core/utils/axios';
import {
  ColumnsMain,
  customSummary,
  ColumnsDansMain,
  customDansSummary,
} from './dataStructure/data';
import { searchList } from './dataStructure/search';
import { start_loading, end_loading } from '../../../core/actions/loader';
import BurtgeList from './BurtgeList';

const FileDownload = require('js-file-download');

const UndsenHurungu = () => {
  const dispatch = useDispatch();

  const query = useQuery();
  const history = useHistory();

  const socket = useSelector((state) => state.socket);
  const data_date = useSelector((state) => state.data_date);
  const tailan_type_map = {
    zuruu: {
      file_name: 'Үндсэн хөрөнгө - Татварын суурь зөрүү.xlsx',
      action_type_secondary: 'export_uh_zuruu',
    },
  };
  const [sumType, seTsumType] = useState();
  const [selectedHurungu, seTselectedHurungu] = useState({});
  const [burtgel, seTburtgel] = useState(false);
  const [tableData, setTableData] = useState({
    result_list: [],
    result_json: {},
    total_list: {},
    total_size: 0,
    validtion_info: {
      validated: true,
      error_message: [],
    },
  });

  const select_data = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        setTableData(data?.content);
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    const page = query.get('page');
    const size = query.get('size');
    const { month } = data_date;
    const search = {
      tab: query.get('tab'),
      type: sumType || 'hurungu',
      dans_dugaar: query.get('dans_dugaar'),
      hurungu: query.get('hurungu'),
    };
    socket.emit('request_in', {
      channel: 'ts003_view',
      content: {
        action_type: 'tur_zuruu_page',
        month,
        page,
        size,
        search,
      },
    });
    dispatch(start_loading({}));
  };

  const onSelectHurungu = (row) => {
    seTselectedHurungu(row);
    seTburtgel(true);
  };

  const clearPage = () => {
    const proplist = ['page', 'size'];
    let not_change = true;
    proplist.forEach((name) => {
      if (query.get(name)) {
        query.delete(name);
        not_change = false;
      }
    });
    if (not_change) {
      select_data();
    } else {
      history.push({ search: query.toString() });
    }
  };

  useEffect(() => {
    if (sumType) {
      clearPage();
    }
  }, [sumType]);

  useEffect(() => {
    select_data();
  }, [query, data_date]);

  const fetch_TC_valiut = () => {
    socket.on('request_out', (data) => {
      if (data.status === '0') {
        socket.removeAllListeners('request_out');
        newAlert({ type: 'success', msg: data.message });
        dispatch(end_loading({}));
        select_data();
      } else if (data.status === '1') {
        socket.removeAllListeners('request_out');
        newAlert({ type: 'error', msg: data.message });
        dispatch(end_loading({}));
        select_data();
      } else if (data.status === '3') {
        newAlert({ type: 'warning', msg: data.message });
        dispatch(end_loading({}));
      }
    });
    socket.emit('request_in', {
      channel: 'ts003_calculate',
      content: {
        action_type: 'calc_tur_zuruu',
        first_year: false,
      },
    });
    dispatch(start_loading({}));
  };

  const get_du_nemegdel = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай хуулагдлаа' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts003_api',
      content: {
        return_channel: 'ts001_02_tur_zuruu_control',
        month: String(data_date.month),
        action_type: 'du_nemegdel_set',
      },
    });
    dispatch(start_loading({}));
  };

  const clear_du_nemegdel = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');
      if (data.status === '0') {
        newAlert({ type: 'success', msg: 'Амжилттай цэвэрлэлээ' });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }
      dispatch(end_loading({}));
    });
    socket.emit('request_in', {
      channel: 'ts001_02_tur_zuruu_control',
      content: { action_type: 'du_nemegdel_clear', month: data_date?.month },
    });
    dispatch(start_loading({}));
  };

  const exportTailan = () => {
    socket.on('request_out', (data) => {
      socket.removeAllListeners('request_out');

      if (data.status === '0') {
        const file_export_address_link = `/get_file?file_id=${data.content.file_id}`;
        axios.get('export', file_export_address_link, {}, {}, (event) => {
          FileDownload(event, tailan_type_map.zuruu.file_name);
        });
      } else {
        newAlert({ type: 'error', msg: 'Системд алдаа гарлаа' });
      }

      dispatch(end_loading({}));
    });

    const { month } = data_date;
    const search = {
      type: sumType || 'hurungu',
      dans_dugaar: query.get('dans_dugaar'),
      hurungu: query.get('hurungu'),
    };

    socket.emit('request_in', {
      channel: 'ts003_api',
      content: {
        action_type: 'tur_zuruu_export',
        return_channel: 'ss010_00_export_file',
        month,
        search,
      },
    });

    dispatch(start_loading({}));
  };

  const title = () => (
    <>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<ExportOutlined />}
        onClick={() => { exportTailan(); }}
      >
        Экспорт
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<CalculatorOutlined />}
        onClick={() => {
          customConfirm({
            title: 'Тооцоолсон дахин үнэлгээний нэмэгдэл холбох уу?',
            onOk: () => get_du_nemegdel(),
          });
        }}
      >
        ДҮНэмэгдэл холбох
      </AntButton>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        icon={<CalculatorOutlined />}
        onClick={() => {
          customConfirm({
            title: 'Тооцоолсон дахин үнэлгээний нэмэгдэл цэвэрлэх үү?',
            onOk: () => clear_du_nemegdel(),
          });
        }}
      >
        ДҮНэмэгдэл цэвэрлэх
      </AntButton>
      <CanView allowedRole="006_1">
        <AntButton
          className="add-btn"
          type="primary"
          size="small"
          icon={<CalculatorOutlined />}
          onClick={() => {
            customConfirm({
              title: 'Түр зөрүү татахыг зөвшөөрөх үү?',
              onOk: () => fetch_TC_valiut(),
            });
          }}
        >
          Түр зөрүү татах
        </AntButton>
      </CanView>
      <AntButton
        className="add-btn"
        type="primary"
        size="small"
        onClick={() => {
          seTsumType(sumType === 'dans' ? 'hurungu' : 'dans');
        }}
      >
        {sumType === 'dans' ? 'Дансаар' : 'Хөрөнгөөр'}
      </AntButton>
    </>
  );

  const mainTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsMain({ onSelectHurungu })}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 350px)',
        }}
        summaryList={{
          sumColumn: customSummary,
          sumDataSource: tableData.total_list,
        }}
      />
    </div>
  );

  const mainDansTable = () => (
    <div className="table-antd-div no-scroll">
      <Table
        isSearch
        searchList={searchList}
        title={title}
        columns={ColumnsDansMain()}
        total={tableData.total_size}
        dataSource={tableData.result_list}
        scroll={{
          y: 'calc(100vh - 350px)',
        }}
        summaryList={{
          sumColumn: customDansSummary,
          sumDataSource: tableData.total_list,
        }}
      />
    </div>
  );

  const onCancelBurtgel = () => {
    seTburtgel(false);
    seTselectedHurungu({});
    select_data();
  };

  return (
    <>
      <BurtgeList
        hurunguData={selectedHurungu}
        closeDrawer={onCancelBurtgel}
        open={burtgel}
      />
      {sumType === 'dans' ? mainDansTable() : mainTable()}
    </>
  );
};

export default UndsenHurungu;
