/* eslint-disable indent */
// /* eslint-disable indent */
import React from 'react';
import { roundFloat } from 'src/core/utils/formater';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table as AntTable, Button as AntButton } from 'antd';
import { Warning } from 'src/components';
import CanView from 'src/components/permission/canview';
import { customConfirm } from 'src/components/common/confirmModal';

export const mainColumns = ({ onSelectHurungu = () => {} }) => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {
          // eslint-disable-next-line operator-linebreak
          row?.zuruu_huramt_elegdel ||
          // eslint-disable-next-line operator-linebreak
          row?.zuruu_too_shirheg ||
          // eslint-disable-next-line operator-linebreak
          row?.zuruu_urtug ||
          row?.zuruu_anh_urtug ? (
            <Warning show listWarning={['Холбосон дүн таараагүй байна']} />
          ) : (
            <></>
          )
        }
      </>
    ),
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
    render: (_, row) => (
      <AntButton
        align="center"
        type="link"
        size="small"
        className="underline"
        onClick={() => onSelectHurungu(row)}
      >
        {row?.code}
      </AntButton>
    ),
  },
  {
    title: 'Хөрөнгийн нэр',
    dataIndex: 'ner',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Хэмжих нэгж',
    dataIndex: 'hemjih_negj',
    align: 'center',
    width: 100,
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг',
        dataIndex: 'urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдэл',
        dataIndex: 'huramt_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const mainDansSumColumns = () => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {
          // eslint-disable-next-line operator-linebreak
          row?.zuruu_huramt_elegdel ||
          // eslint-disable-next-line operator-linebreak
          row?.zuruu_too_shirheg ||
          // eslint-disable-next-line operator-linebreak
          row?.zuruu_urtug ||
          row?.zuruu_anh_urtug ? (
            <Warning show listWarning={['Холбосон дүн таараагүй байна']} />
          ) : (
            <></>
          )
        }
      </>
    ),
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    width: 250,
    fixed: 'left',
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо хэмжээ',
        dataIndex: 'too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг',
        dataIndex: 'urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдэл',
        dataIndex: 'huramt_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const mainColumnsSum = [
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    value: null,
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'too_shirheg',
  },
  {
    align: 'right',
    value: 'urtug',
  },
  {
    align: 'right',
    value: 'huramt_elegdel',
  },
];

export const mainDansSumColumnsSum = [
  {
    value: null,
  },
  {
    align: 'right',
    word: 'Нийт дүн:',
  },
  {
    align: 'right',
    value: 'too_shirheg',
  },
  {
    align: 'right',
    value: 'urtug',
  },
  {
    align: 'right',
    value: 'huramt_elegdel',
  },
];

export const dansColumns = ({
  customEdit = () => {},
  updateAnhUrtug = () => {},
  editUpdateBurtgel = () => {},
  deleteRow = () => {},
  version,
}) => [
  {
    align: 'center',
    width: 30,
    fixed: 'left',
    render: (_, row) => (
      <>
        {!row?.dahin_unelgee_tulsan && (
          <Warning
            show
            listWarning={[
              'Анхны өртгийн өөрчлөлтийн өртгийн дүн таараагүй байна',
            ]}
          />
        )}
      </>
    ),
  },
  {
    title: 'Бүртгэлийн дугаар',
    dataIndex: 'burtgel_dugaar',
    align: 'left',
    width: 150,
    fixed: 'left',
  },
  {
    title: 'Анхны өртөг',
    dataIndex: 'urtug_anh',
    align: 'right',
    width: 160,
    render: (_, row) => (
      <>
        {row.bodit_urtug_anh > 0
          ? roundFloat(row?.bodit_urtug_anh)
          : roundFloat(row?.urtug_anh)}
        {version?.is_first_year && (
          <>
            {'    '}
            <CanView allowedRole="006_1">
              <EditOutlined size="small" onClick={() => updateAnhUrtug(row)} />
            </CanView>
          </>
        )}
      </>
    ),
  },
  {
    title: 'Эхний үлдэгдэл',
    children: [
      {
        title: 'Тоо ширхэг',
        dataIndex: 'too_shirheg',
        align: 'right',
        width: 70,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Өртөг',
        dataIndex: 'urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хуримтлагдсан элэгдэл',
        dataIndex: 'huramt_elegdel',
        align: 'right',
        width: 160,
        render: (_, row) => (
          <>
            {roundFloat(row?.huramt_elegdel)}
            {version?.is_first_year && (
              <>
                {'    '}
                <CanView allowedRole="006_1">
                  <EditOutlined
                    size="small"
                    onClick={() => editUpdateBurtgel(row)}
                  />
                </CanView>
              </>
            )}
          </>
        ),
      },
    ],
  },
  {
    title: 'Анхны өртгийн өөрчлөлт',
    dataIndex: 'dahin_unelgee_urtug',
    align: 'right',
    width: 160,
    render: (_, row) => (
      <>
        {roundFloat(row?.dahin_unelgee_urtug)}
        {version?.is_first_year && (
          <>
            {'    '}
            <CanView allowedRole="006_1">
              <EditOutlined size="small" onClick={() => customEdit(row)} />
            </CanView>
          </>
        )}
      </>
    ),
  },
  {
    title: '',
    align: 'center',
    width: 30,
    fixed: 'right',
    render: (_, row) => (
      <>
        {version?.is_first_year && (
          <>
            <CanView allowedRole="006_1">
              <DeleteOutlined
                size="small"
                onClick={
                  () =>
                    customConfirm({ onOk: () => deleteRow(row?.burtgel_id) })
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </CanView>
          </>
        )}
      </>
    ),
  },
];

export const DahinColumns = ({
  deleteRow = () => {},
  updateRow = () => {},
}) => [
  {
    title: 'Огноо',
    dataIndex: 'ognoo',
    align: 'left',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Хэлбэр',
    dataIndex: 'helber_text',
    align: 'left',
    width: 100,
    ellipsis: true,
  },
  {
    title: 'Арга',
    dataIndex: 'arga_text',
    align: 'left',
    width: 200,
    ellipsis: true,
  },
  {
    title: 'Анхны өртөгт',
    children: [
      {
        title: 'Нэмэгдсэн',
        dataIndex: 'dun_nemeh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдсан',
        dataIndex: 'dun_hasah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Хуримтлагдсан элэгдэлд',
    children: [
      {
        title: 'Нэмэгдсэн',
        dataIndex: 'dun_nemeh_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдсан',
        dataIndex: 'dun_hasah_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Шинэчилсэн',
    children: [
      {
        title: 'Ашиглаж дуусах хугацаа',
        dataIndex: 'ashiglaj_duusah_uurchlult',
        align: 'right',
        width: 150,
      },
      {
        title: 'Үлдэх өртөг',
        dataIndex: 'urtug_uldeh_uurchlult',
        align: 'right',
        width: 160,
        render: (_, row) => (
          <>
            <div>
              <p>
                {roundFloat(row?.urtug_uldeh_uurchlult)}
                {'    '}
                <EditOutlined onClick={() => updateRow(row)} />
              </p>
            </div>
          </>
        ),
      },
      {
        title: '',
        align: 'center',
        width: 30,
        render: (_, row) => (
          <>
            <DeleteOutlined
              onClick={
                () => customConfirm({ onOk: () => deleteRow(row?.id) })
                // eslint-disable-next-line react/jsx-curly-newline
              }
            />
          </>
        ),
      },
    ],
  },
];

export const DahinAllColumns = () => [
  {
    title: 'Хөрөнгийн код',
    dataIndex: 'code',
    align: 'left',
    width: 120,
    fixed: 'left',
  },
  {
    title: 'Үндсэн хөрөнгийн',
    children: [
      {
        title: 'Нэр',
        dataIndex: 'ner',
        align: 'left',
        width: 200,
        ellipsis: true,
      },
      {
        title: 'Бүртгэлийн дугаар',
        dataIndex: 'burtgel_dugaar',
        align: 'left',
        width: 150,
      },
      {
        title: 'Ашиглаж эхэлсэн огноо',
        dataIndex: 'ashiglaj_ehelsen',
        align: 'center',
        width: 150,
      },
      {
        title: 'Анхны өртөг',
        dataIndex: 'urtug_anh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Өөрчлөлт',
    children: [
      {
        title: 'Огноо',
        dataIndex: 'ognoo',
        align: 'center',
        width: 150,
      },
      {
        title: 'Дахин үнэлгээ / Их засвар',
        dataIndex: 'helber_text',
        align: 'left',
        width: 110,
      },
      {
        title: 'Арга',
        dataIndex: 'arga_text',
        align: 'left',
        width: 150,
      },
    ],
  },
  {
    title: 'Анхны өртөг',
    children: [
      {
        title: 'Нэмэгдсэн',
        dataIndex: 'dun_nemeh',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдсан',
        dataIndex: 'dun_hasah',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: ' Хуримтлагдсан элэгдэл',
    children: [
      {
        title: 'Нэмэгдсэн',
        dataIndex: 'dun_nemeh_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Хасагдсан',
        dataIndex: 'dun_hasah_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Шинэчилсэн',
    children: [
      {
        title: 'Ашиглаж дуусах огноо',
        dataIndex: 'ashiglaj_duusah_uurchlult',
        align: 'center',
        width: 150,
      },
      {
        title: 'Үлдэх өртөг',
        dataIndex: 'urtug_uldeh_uurchlult',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const ErrorListColumns = () => [
  {
    title: 'Үндсэн данс',
    children: [
      {
        title: 'Дугаар',
        dataIndex: 'dans_dugaar',
        align: 'left',
        width: 250,
      },
      {
        title: 'Нэр',
        dataIndex: 'dans_dugaar_ner',
        align: 'left',
        width: 200,
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Элэгдэл данс',
    children: [
      {
        title: 'Дугаар',
        dataIndex: 'dans_dugaar_elegdel',
        align: 'left',
        width: 250,
      },
      {
        title: 'Нэр',
        dataIndex: 'dans_dugaar_elegdel_ner',
        align: 'left',
        width: 200,
        ellipsis: true,
      },
    ],
  },
  {
    title: 'Данс',
    children: [
      {
        title: 'Өртөг',
        dataIndex: 'dun_urtug',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Элэгдэл',
        dataIndex: 'dun_elegdel',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
  {
    title: 'Үндсэн хөрөнгийн товчоо',
    children: [
      {
        title: 'Өртөг',
        dataIndex: 'dun_urtug_hurungu',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
      {
        title: 'Элэгдэл',
        dataIndex: 'dun_elegdel_hurungu',
        align: 'right',
        width: 150,
        render: (_) => roundFloat(_),
      },
    ],
  },
];

export const CustomSum = ({ data = null }) => (
  <AntTable.Summary fixed size="small">
    <AntTable.Summary.Row>
      <AntTable.Summary.Cell align="right" colSpan={2}>
        Нийт дүн:
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>Холбосон</p>
        <p>Дансны</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.linked_too_shirheg)}</p>
        <p>{roundFloat(data?.too_shirheg)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.linked_urtug)}</p>
        <p>{roundFloat(data?.urtug)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell align="right">
        <p>{roundFloat(data?.linked_huramt_elegdel)}</p>
        <p>{roundFloat(data?.huramt_elegdel)}</p>
      </AntTable.Summary.Cell>
      <AntTable.Summary.Cell colSpan={2} />
    </AntTable.Summary.Row>
  </AntTable.Summary>
);
