import React from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export const customColumns = ({
  customEdit = () => {},
  customDelete = () => {},
  dansProps = null,
  getTypeAngilal = () => {},
  getTypeTurZuruu = () => {},
  getTypeJournal = () => {},
}) => [
  {
    title: '#',
    dataIndex: 'count',
    align: 'left',
    titleAlign: 'center',
    width: 30,
    fixed: 'left',
  },
  {
    align: 'center',
    width: 30,
    render: (_, row) => {
      if (row?.has_warning === true) {
        return (
          <div>
            <WarningOutlined />
          </div>
        );
      }
      return (
        <div>
          <CheckOutlined />
        </div>
      );
    },
    fixed: 'left',
  },
  {
    title: 'Дансны дугаар',
    dataIndex: 'dans_dugaar',
    align: 'left',
    titleAlign: 'center',
    width: 250,
  },
  {
    title: 'Дансны нэр',
    dataIndex: 'dans_ner',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
  },
  {
    title: 'Валютын төрөл',
    dataIndex: 'valiut_torol',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Дансны төрөл',
    dataIndex: 'dans_turul',
    align: 'center',
    titleAlign: 'center',
    width: 80,
  },
  {
    title: 'Дансны ангилал',
    dataIndex: 'dans_angilal_id',
    align: 'left',
    titleAlign: 'center',
    width: 300,
    ellipsis: true,
    render: (_, row) => ({
      children: getTypeAngilal(
        dansProps,
        row?.dans_turul,
        row?.dans_angilal_id
      ),
    }),
  },
  {
    title: 'Туслах журнал',
    dataIndex: 'tuslah_journal_type',
    align: 'center',
    titleAlign: 'center',
    width: 150,
    ellipsis: true,
    render: (_, row) => ({
      children: getTypeJournal(row?.tuslah_journal_type),
    }),
  },
  {
    title: 'Түр зөрүүгийн төрөл',
    dataIndex: 'tur_zuruu_turul_id',
    align: 'center',
    titleAlign: 'center',
    width: 200,
    ellipsis: true,
    render: (_, row) => ({
      children: getTypeTurZuruu(row?.tur_zuruu_turul_id),
    }),
  },
  {
    title: 'Засах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <div>
        <EditOutlined onClick={() => customEdit(row)} />
      </div>
    ),
    fixed: 'right',
  },
  {
    title: 'Устгах',
    align: 'center',
    titleAlign: 'center',
    width: 50,
    render: (_, row) => (
      <div>
        <DeleteOutlined onClick={() => customDelete(row)} />
      </div>
    ),
    fixed: 'right',
  },
];
