import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { concat } from 'lodash';

import Home from '../../pages/home';
// --------------------------------------------------------
import S1_1_TatvarJil from '../../pages/s1_1_TatvarJil';
import S1_2_RoleAdd from '../../pages/s1_2_ErhiinTohirgoo';
import S1_3_UserAdd from '../../pages/s1_3_Hereglegch';
import S1_4_AuditLog from '../../pages/s1_4_AuditLog';
import s1_4_SystemLog from '../../pages/s1_4_AuditLog/systemLog';
import S1_5_SysLog from '../../pages/s1_5_SysLog';
import S1_5_TmzMonitoring from '../../pages/tmzMonitoring';
// --------------------------------------------------------
import S2_1_DansUne from '../../pages/s2_1_DansniiUne';
import S2_1_DansUne_Client from '../../pages/s2_1_DansniiUne/hariltsagch';
import S2_1_DansUne_Asset from '../../pages/s2_1_DansniiUne/undsenHurungu';
// --------------------------------------------------------
import S2_2_TatvarSuuri from '../../pages/s2_2_TatvarSuuriZuruu';
import S2_2_TatvarSuuri_Client from '../../pages/s2_2_TatvarSuuriZuruu/tatvarSuuriAvlagaUglug';
import S2_2_TatvarSuuri_Asset from '../../pages/s2_2_TatvarSuuriZuruu/zuruuUndsenHurungu';
import S2_2_TatvarSuuri_Cash from '../../pages/s2_2_TatvarSuuriZuruu/tatvarSuuriMungunHurungu';
// --------------------------------------------------------
import S2_7_TatvarTohirgoo from '../../pages/s2_7_TatvarTohirgoo';
import S2_7_TatvarTohirgoo_ShalgahBalance from '../../pages/s2_7_TatvarTohirgoo/shalgahBalance';
import S2_7_TatvarTohirgoo_Tohirgoo from '../../pages/s2_7_TatvarTohirgoo/taxJournal';
import S2_7_TatvarTohirgoo_UndsenHurungu from '../../pages/s2_7_TatvarTohirgoo/zuruuUndsenHurungu';
import S2_7_TatvarTohirgoo_AvlagaUglug from '../../pages/s2_7_TatvarTohirgoo/zuruuAvlagaUglug';
// --------------------------------------------------------
import S2_3_TurZuruu from '../../pages/s2_3_TurZuruu';
import S2_3_TurZuruu_Client from '../../pages/s2_3_TurZuruu/hariltsagchaar';
import S2_3_TurZuruu_Asset from '../../pages/s2_3_TurZuruu/hurunguur';
// --------------------------------------------------------
import S2_4_DundajHuvi from '../../pages/s2_4_DundajHuvi';
// --------------------------------------------------------
import S2_5_HoishTatHurungu from '../../pages/s2_5_HoishTatvarHurungu';
import S2_5_HoishTatHurungu_Client from '../../pages/s2_5_HoishTatvarHurungu/hariltsagchaar';
import S2_5_HoishTatHurungu_Asset from '../../pages/s2_5_HoishTatvarHurungu/hurunguur';
// --------------------------------------------------------
import S2_6_HoishTatUrTulbur from '../../pages/s2_6_HoishTatvarUrTulbur';
import S2_6_HoishTatUrTulbur_Client from '../../pages/s2_6_HoishTatvarUrTulbur/hariltsagchaar';
import S2_6_HoishTatUrTulbur_Asset from '../../pages/s2_6_HoishTatvarUrTulbur/hurunguur';
// --------------------------------------------------------
import S3_1_UndsenMedeelelDans from '../../pages/s3_1_DansMedeelel';
import S3_1_UndsenMedeelelDans_TurZuruu from '../../pages/s3_1_DansMedeelel/turZuruuTurul';
import S3_1_UndsenMedeelelDans_Elegdel from '../../pages/s3_1_DansMedeelel/dansHolboosElegdel';
// import S3_1_UndsenMedeelelDans_Hyazgaar from '../../pages/s3_1_DansMedeelel/dansHolboosHyazgaar';
// import S3_1_UndsenMedeelelDans_Nemelt from '../../pages/s3_1_DansMedeelel/dansHolboosNemelt';
import S3_1_UndsenMedeelelDans_Zagvar from '../../pages/s3_1_DansMedeelel/dansZagvarBuleg';
import S3_1_UndsenMedeelelDans_DedAngilal from '../../pages/s3_1_DansMedeelel/dansAngilalDed';
// --------------------------------------------------------
import S3_2_UndsenMedeelelHariltsagch from '../../pages/s3_2_HariltsagchMedeelel';
import S3_2_UndsenMedeelelHariltsagch_Hamaaraltai from '../../pages/s3_2_HariltsagchMedeelel/hariltsanHamaaraltaiEtgeed';
import S3_2_UndsenMedeelelHariltsagch_Huvitsaa from '../../pages/s3_2_HariltsagchMedeelel/huvitsaaEzemshigch';
// --------------------------------------------------------
import S3_3_UndsenMedeelelHurunguMedeelel from '../../pages/s3_3_UndsenHurunguMedeelel';
import S3_3_UndsenMedeelelHurunguMedeelel_DahinUnelgee from '../../pages/s3_3_UndsenHurunguMedeelel/undsenHurunguDUIhzasvar';
import S3_4_UndsenMedeelelHurunguMedeelel_Zasvar from '../../pages/s3_3_UndsenHurunguMedeelel/undsenHurunguUrsgalZasvar';
// --------------------------------------------------------
import S3_5_AldagdalBurtgel from '../../pages/s3_5_AldagdalBurtgel';
// --------------------------------------------------------
import S4_1_TatvarTulugchMedeelel from '../../pages/s4_1_TatvarTulugch';
import S4_2_HereglegchMedeelel from '../../pages/userProfile/newUserProfile';
// --------------------------------------------------------
import T1_1_GuilgeeJagsaalt from '../../pages/1_1_GuilgeeJagsaalt';
import T1_1_GuilgeeJagsaalt_OgogdolBatalgaajuulah from '../../pages/1_1_GuilgeeJagsaalt/ogogdolBatalgaajuulah/newOgogdolBatalgaajuulah';
// --------------------------------------------------------
import T1_2_ZardalHyazgaarUgugdul from '../../pages/1_2_ZardalHyazgaarUgugdul';
import T1_2_ZardalHyazgaarUgugdul_Tomilolt from '../../pages/1_2_ZardalHyazgaarUgugdul/tomilolt';
import T1_2_ZardalHyazgaarUgugdul_Gamshig from '../../pages/1_2_ZardalHyazgaarUgugdul/gamshig';
import T1_2_ZardalHyazgaarUgugdul_Tsalin from '../../pages/1_2_ZardalHyazgaarUgugdul/yoslolHundetgel';
import T1_2_ZardalHyazgaarUgugdul_Huu from '../../pages/1_2_ZardalHyazgaarUgugdul/huugiinZardal';
import T1_2_ZardalHyazgaarUgugdul_Zasvar from '../../pages/1_2_ZardalHyazgaarUgugdul/ursgalZaswar';
// --------------------------------------------------------
import T2_1_GuilgeeBalance from '../../pages/2_1_GuilgeeBalance';
import T2_1_GuilgeeBalance_Cash from '../../pages/2_1_GuilgeeBalance/mungunHurungu';
// --------------------------------------------------------
import T2_2_AvlagaUglugTovchoo from '../../pages/2_2_AvlagaUglugTovchoo';
// --------------------------------------------------------
import T2_3_UndsenHurunguTovchoo from '../../pages/2_3_UndsenHurunguTovchoo';
// import T2_3_UndsenHurunguTovchoo_Jagsaalt
// from '../../pages/2_3_UndsenHurunguTovchoo/undsenHurunguJagsaalt';
// import T2_3_UndsenHurunguTovchoo_C1 from '../../pages/2_3_UndsenHurunguTovchoo/undsenHurunguC1';
// --------------------------------------------------------
import T3_2_TatvarSuuriGuilgeeBalance from '../../pages/3_2_TatvarSuuriGuilgeeBalance';
import T3_2_TatvarSuuriGuilgeeBalance_Cash from '../../pages/3_2_TatvarSuuriGuilgeeBalance/mungunHurungu';
// --------------------------------------------------------
import T3_3_TatvarSuuriAvlagaUglugTovchoo from '../../pages/3_3_TatvarSuuriAvlagaUglug';
// --------------------------------------------------------
import T3_4_TatvarSuuriUndsenHurungu from '../../pages/3_4_TatvarSuuriUndsenHurungu';
// import T3_4_TatvarSuuriUndsenHurungu_Jagsaalt
// from '../../pages/3_4_TatvarSuuriUndsenHurungu/undsenHurunguJagsaaltTS';
// --------------------------------------------------------
import T3_1_TatvarSuuriZuruu from '../../pages/3_1_TatvarSuuriZuruu';
import T3_1_TatvarSuuriZuruu_Client from '../../pages/3_1_TatvarSuuriZuruu/zuruuAvlagaUglug';
import T3_1_TatvarSuuriZuruu_Asset from '../../pages/3_1_TatvarSuuriZuruu/zuruuUndsenHurungu';
// --------------------------------------------------------
import T4_1_TatvarShalgahBalance from '../../pages/4_1_ShalgahBalance';
import T4_1_TatvarShalgahBalance_Tohirgoo from '../../pages/4_1_ShalgahBalance/taxJournal';
import T4_1_TatvarShalgahBalance_Buleg from '../../pages/4_1_ShalgahBalance/dansZagvarBuleg';
import T4_1_TatvarShalgahBalance_HanshTohirgoo from '../../pages/4_1_ShalgahBalance/hanshJournal';
// --------------------------------------------------------
import T4_2_ZardalHyazgaarCalculate from '../../pages/4_2_ZardalHyazgaarCalculate';
import T4_2_ZardalHyazgaarCalculate_Tomilolt from '../../pages/4_2_ZardalHyazgaarCalculate/tomilolt';
import T4_2_ZardalHyazgaarCalculate_Huu from '../../pages/4_2_ZardalHyazgaarCalculate/huu';
import T4_2_ZardalHyazgaarCalculate_Zasvar from '../../pages/4_2_ZardalHyazgaarCalculate/ursgalZasvar';
// --------------------------------------------------------
import T4_3_Hungulult from '../../pages/4_3_Hungulult';
// --------------------------------------------------------
import T5_1_TurZuruu from '../../pages/5_1_TurZuruu';
import T5_1_TurZuruu_Client from '../../pages/5_1_TurZuruu/hariltsagchaar';
import T5_1_TurZuruu_Asset from '../../pages/5_1_TurZuruu/hurunguur';
// --------------------------------------------------------
import T5_5_TatvarDundajHuvi from '../../pages/5_5_TatvarDundajHuvi';
// --------------------------------------------------------
import T5_2_HoishlogdsonTatvarHurungu from '../../pages/5_2_HoishTatvarHurungu';
import T5_2_HoishlogdsonTatvarHurungu_Client from '../../pages/5_2_HoishTatvarHurungu/hariltsagchaar';
import T5_2_HoishlogdsonTatvarHurungu_Asset from '../../pages/5_2_HoishTatvarHurungu/hurunguur';
// --------------------------------------------------------
import T5_3_HoishlogdsonTatvarUrTulbur from '../../pages/5_3_HoishTatvarUrTulbur';
import T5_3_HoishlogdsonTatvarUrTulbur_Client from '../../pages/5_3_HoishTatvarUrTulbur/hariltsagchaar';
import T5_3_HoishlogdsonTatvarUrTulbur_Asset from '../../pages/5_3_HoishTatvarUrTulbur/hurunguur';
// --------------------------------------------------------
import T5_4_Aldagdal from '../../pages/5_4_Aldagdal';
import T5_4_Aldagdal_Tootsooloh from '../../pages/5_4_Aldagdal/aldagdalCalculate';
// --------------------------------------------------------
import T7_1_AjliinHusnegt from '../../pages/7_1_AjliinHusnegt';
// --------------------------------------------------------
import T7_2_TailanZuruu from '../../pages/7_2_TailanZuruu';
// --------------------------------------------------------
import T7_3_DansHudulguun from '../../pages/7_3_DansHudulguun';
// --------------------------------------------------------
import T6_1_TailanTatvar from '../../pages/6_1_TailanTatvar';
import T6_1_TailanTatvar_HM02_1 from '../../pages/6_1_TailanTatvar/hm02_1';
import T6_1_TailanTatvar_HM02_2 from '../../pages/6_1_TailanTatvar/hm02_2';
import T6_1_TailanTatvar_HM02_3a from '../../pages/6_1_TailanTatvar/hm02_3a';
import T6_1_TailanTatvar_HM02_3b from '../../pages/6_1_TailanTatvar/hm02_3b';
import T6_1_TailanTatvar_HM02_3v from '../../pages/6_1_TailanTatvar/hm02_3v';
import T6_1_TailanTatvar_HM02_4 from '../../pages/6_1_TailanTatvar/hm02_aldagdal';
import T6_1_TailanTatvar_HM02_5 from '../../pages/6_1_TailanTatvar/hm02_5';
import T6_1_TailanTatvar_HM02_6 from '../../pages/6_1_TailanTatvar/hm02_6';
import T6_1_TailanTatvar_HM02_7 from '../../pages/6_1_TailanTatvar/hm02_7';
import T6_1_TailanTatvar_HM02_8 from '../../pages/6_1_TailanTatvar/hm02_hongololt';
// --------------------------------------------------------
import E_tax from '../../pages/e_tax';

export const routeList = {
  Home: {
    path: '/main',
    component: Home,
    permission: false,
  },
  S1_1_TatvarJil: {
    path: '/main/tohirgoo/tatvarJil',
    component: S1_1_TatvarJil,
    permission: false,
  },
  S1_2_RoleAdd: {
    path: '/main/tohirgoo/roleAdd',
    component: S1_2_RoleAdd,
    permission: false,
  },
  S1_3_UserAdd: {
    path: '/main/tohirgoo/userAdd',
    component: S1_3_UserAdd,
    permission: false,
  },
  S1_4_AuditLog: {
    title: 'Tax',
    path: '/main/tohirgoo/auditLog',
    component: S1_4_AuditLog,
    permission: false,
    children: [
      {
        title: 'System',
        path: '/main/tohirgoo/auditLogSystem',
        component: s1_4_SystemLog,
        permission: false,
      },
    ],
  },
  S1_5_SysLog: {
    title: 'Системийн лог',
    path: '/main/tohirgoo/sysLog',
    component: S1_5_SysLog,
    permission: true,
  },
  S1_5_TmzMonitoring: {
    path: '/main/tohirgoo/tmzMonitoring',
    component: S1_5_TmzMonitoring,
    permission: true,
  },
  S2_1_DansUne: {
    title: 'Нийт данс',
    path: '/main/ehniiUldegdel/dansUne',
    component: S2_1_DansUne,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/ehniiUldegdel/dansUne/client',
        component: S2_1_DansUne_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/ehniiUldegdel/dansUne/asset',
        component: S2_1_DansUne_Asset,
        permission: false,
      },
    ],
  },
  S2_2_TatvarSuuri: {
    title: 'Нийт данс',
    path: '/main/ehniiUldegdel/tatvarSuuri',
    component: S2_2_TatvarSuuri,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/ehniiUldegdel/tatvarSuuri/avlagaUglug',
        component: S2_2_TatvarSuuri_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/ehniiUldegdel/tatvarSuuri/undsenHurungu',
        component: S2_2_TatvarSuuri_Asset,
        permission: false,
      },
      {
        title: 'Мөнгөн хөрөнгө',
        path: '/main/ehniiUldegdel/tatvarSuuri/mungunHurungu',
        component: S2_2_TatvarSuuri_Cash,
        permission: false,
      },
    ],
  },
  S2_7_TatvarTohirgoo: {
    title: 'ДҮ-ТС зөрүү',
    path: '/main/ehniiUldegdel/tatvarTohirgoo',
    component: S2_7_TatvarTohirgoo,
    permission: false,
    children: [
      {
        title: 'Татварын шалгах баланс',
        path: '/main/ehniiUldegdel/tatvarTohirgoo/shalgahBalance',
        component: S2_7_TatvarTohirgoo_ShalgahBalance,
        permission: false,
      },
      {
        title: 'Татварын тохируулгын жагсаалт',
        path: '/main/ehniiUldegdel/tatvarTohirgoo/tohiruulga',
        component: S2_7_TatvarTohirgoo_Tohirgoo,
        permission: false,
      },
      {
        title: 'ДҮ-ТС зөрүү /Үндсэн хөрөнгө/',
        path: '/main/ehniiUldegdel/tatvarTohirgoo/undsenHurungu',
        component: S2_7_TatvarTohirgoo_UndsenHurungu,
        permission: false,
      },
      {
        title: 'ДҮ-ТС зөрүү /Авлага өглөг/',
        path: '/main/ehniiUldegdel/tatvarTohirgoo/avlagaUglug',
        component: S2_7_TatvarTohirgoo_AvlagaUglug,
        permission: false,
      },
    ],
  },
  S2_3_TurZuruu: {
    title: 'Нийт данс',
    path: '/main/ehniiUldegdel/turZuruu',
    component: S2_3_TurZuruu,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/ehniiUldegdel/turZuruu/avlagaUglug',
        component: S2_3_TurZuruu_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/ehniiUldegdel/turZuruu/undsenHurungu',
        component: S2_3_TurZuruu_Asset,
        permission: false,
      },
    ],
  },
  S2_4_DundajHuvi: {
    path: '/main/ehniiUldegdel/dundajHuvi',
    component: S2_4_DundajHuvi,
    permission: false,
  },
  S2_5_HoishTatHurungu: {
    title: 'Нийт данс',
    path: '/main/ehniiUldegdel/hoishTatvarHurungu',
    component: S2_5_HoishTatHurungu,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/ehniiUldegdel/hoishTatvarHurungu/avlagaUglug',
        component: S2_5_HoishTatHurungu_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/ehniiUldegdel/hoishTatvarHurungu/undsenHurungu',
        component: S2_5_HoishTatHurungu_Asset,
        permission: false,
      },
    ],
  },
  S2_6_HoishTatUrTulbur: {
    title: 'Нийт данс',
    path: '/main/ehniiUldegdel/hoishTatvarUrTulbur',
    component: S2_6_HoishTatUrTulbur,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/ehniiUldegdel/hoishTatvarUrTulbur/avlagaUglug',
        component: S2_6_HoishTatUrTulbur_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/ehniiUldegdel/hoishTatvarUrTulbur/undsenHurungu',
        component: S2_6_HoishTatUrTulbur_Asset,
        permission: false,
      },
    ],
  },
  S3_1_UndsenMedeelelDans: {
    title: 'Дансны мэдээлэл',
    path: '/main/undsenMedeelel/dans',
    component: S3_1_UndsenMedeelelDans,
    permission: false,
    children: [
      {
        title: 'Түр зөрүүгийн төрөл бүртгэл',
        path: '/main/undsenMedeelel/dans/TempDiffInfo',
        component: S3_1_UndsenMedeelelDans_TurZuruu,
        permission: false,
      },
      {
        title: 'Данс холболт - Үндсэн хөрөнгө',
        path: '/main/undsenMedeelel/dans/AccountLinkDep',
        component: S3_1_UndsenMedeelelDans_Elegdel,
        permission: false,
      },
      // {
      //   title: 'Данс холболт - Хязгаар',
      //   path: '/main/undsenMedeelel/dans/Hyazgaar',
      //   component: S3_1_UndsenMedeelelDans_Hyazgaar,
      //   permission: false,
      // },
      // {
      //   title: 'Данс холболт - Нэмэлт',
      //   path: '/main/undsenMedeelel/dans/Nemelt',
      //   component: S3_1_UndsenMedeelelDans_Nemelt,
      //   permission: false,
      // },
      {
        title: 'Тохиргооны загвар бүртгэл',
        path: '/main/undsenMedeelel/dans/Zagvar',
        component: S3_1_UndsenMedeelelDans_Zagvar,
        permission: false,
      },
      {
        title: 'Дансны дэд ангилал',
        path: '/main/undsenMedeelel/dans/DedAngilal',
        component: S3_1_UndsenMedeelelDans_DedAngilal,
        permission: false,
      },
    ],
  },
  S3_2_UndsenMedeelelHariltsagch: {
    title: 'Харилцагч',
    path: '/main/undsenMedeelel/hariltsagch',
    component: S3_2_UndsenMedeelelHariltsagch,
    permission: false,
    children: [
      {
        title: 'Харилцан хамааралтай этгээд',
        path: '/main/undsenMedeelel/hariltsagch/hamaaraltaiEtgeed',
        component: S3_2_UndsenMedeelelHariltsagch_Hamaaraltai,
        permission: false,
      },
      {
        title: 'Хөрөнгө оруулагч',
        path: '/main/undsenMedeelel/hariltsagch/huvitsaaEzemshigch',
        component: S3_2_UndsenMedeelelHariltsagch_Huvitsaa,
        permission: false,
      },
    ],
  },
  S3_3_UndsenMedeelelHurunguMedeelel: {
    title: 'Үндсэн хөрөнгө',
    path: '/main/undsenMedeelel/hurungu',
    component: S3_3_UndsenMedeelelHurunguMedeelel,
    permission: false,
    children: [
      {
        title: 'Санхүү - Дахин үнэлгээ, их засвар',
        path: '/main/undsenMedeelel/hurungu/dahinUnelgee',
        component: S3_3_UndsenMedeelelHurunguMedeelel_DahinUnelgee,
        permission: false,
      },
      {
        title: 'Татвар - Их засвар, урсгал засвар',
        path: '/main/undsenMedeelel/hurungu/ursgalZasvar',
        component: S3_4_UndsenMedeelelHurunguMedeelel_Zasvar,
        permission: false,
      },
    ],
  },
  // S3_4_UndsenMedeelelHurunguTatvar: {
  //   title: 'Татвар-Үндсэн хөрөнгийн мэдээлэл',
  //   path: '/main/undsenMedeelel/hurunguTatvar',
  //   component: S3_4_UndsenMedeelelHurunguTatvar,
  //   permission: false,
  //   children: [
  //     {
  //       title: 'Татварын суурь - Өртгийн өөрчлөлт',
  //       path: '/main/undsenMedeelel/hurunguTatvar/ursgalZasvar',
  //       component: S3_4_UndsenMedeelelHurunguTatvar_Zasvar,
  //       permission: false,
  //     },
  //   ],
  // },
  S3_5_AldagdalBurtgel: {
    path: '/main/undsenMedeelel/aldagdalBurtgel',
    component: S3_5_AldagdalBurtgel,
    permission: false,
  },
  S4_1_TatvarTulugchMedeelel: {
    path: '/main/tatvarTulugch/medeelel',
    component: S4_1_TatvarTulugchMedeelel,
    permission: false,
  },
  S4_2_HereglegchMedeelel: {
    path: '/main/hereglegchMedeelel',
    component: S4_2_HereglegchMedeelel,
    permission: false,
  },
  T1_1_GuilgeeJagsaalt: {
    title: 'Өгөгдөл оруулах',
    path: '/main/ugugdulOruulah/jagsaalt',
    component: T1_1_GuilgeeJagsaalt,
    permission: false,
    children: [
      {
        title: 'Өгөгдөл баталгаажуулах',
        path: '/main/ugugdulOruulah/jagsaalt/ogogdolBatalgaajuulah',
        component: T1_1_GuilgeeJagsaalt_OgogdolBatalgaajuulah,
        permission: false,
      },
    ],
  },
  T1_2_ZardalHyazgaarUgugdul: {
    title: 'Татварын зардал',
    path: '/main/ugugdulOruulah/hyazgaar',
    component: T1_2_ZardalHyazgaarUgugdul,
    permission: false,
    children: [
      {
        title: 'Томилолтын зардал',
        path: '/main/ugugdulOruulah/hyazgaar/tomilolt',
        component: T1_2_ZardalHyazgaarUgugdul_Tomilolt,
        permission: false,
      },
      {
        title: 'Гамшгийн хохирлын зардал',
        path: '/main/ugugdulOruulah/hyazgaar/gamshig',
        component: T1_2_ZardalHyazgaarUgugdul_Gamshig,
        permission: false,
      },
      {
        title: 'Цалин, шууд бус орлогын зардал',
        path: '/main/ugugdulOruulah/hyazgaar/tsalin',
        component: T1_2_ZardalHyazgaarUgugdul_Tsalin,
        permission: false,
      },
      {
        title: 'Хүүгийн зардал',
        path: '/main/ugugdulOruulah/hyazgaar/huu',
        component: T1_2_ZardalHyazgaarUgugdul_Huu,
        permission: false,
      },
      {
        title: 'Урсгал засварын зардал',
        path: '/main/ugugdulOruulah/hyazgaar/ursgalZasvar',
        component: T1_2_ZardalHyazgaarUgugdul_Zasvar,
        permission: false,
      },
    ],
  },
  T2_1_GuilgeeBalance: {
    title: 'Гүйлгээ баланс',
    path: '/main/dansniiUne/guilgeeBalance',
    component: T2_1_GuilgeeBalance,
    permission: false,
    children: [
      {
        title: 'Мөнгөн хөрөнгө',
        path: '/main/dansniiUne/guilgeeBalance/mungunHurungu',
        component: T2_1_GuilgeeBalance_Cash,
        permission: false,
      },
    ],
  },
  T2_2_AvlagaUglugTovchoo: {
    path: '/main/dansniiUne/avlagaUglugTovchoo',
    component: T2_2_AvlagaUglugTovchoo,
    permission: false,
  },
  T2_3_UndsenHurunguTovchoo: {
    title: 'Үндсэн хөрөнгийн товчоо',
    path: '/main/dansniiUne/undsenHurunguTovchoo',
    component: T2_3_UndsenHurunguTovchoo,
    permission: false,
    // children: [
    //   {
    //     title: 'Санхүү ҮХ-ийн баримтын жагсаалт',
    //     path: '/main/dansniiUne/undsenHurunguTovchoo/Jagsaalt',
    //     component: T2_3_UndsenHurunguTovchoo_Jagsaalt,
    //     permission: false,
    //   },
    //   {
    //     title: 'С1',
    //     path: '/main/dansniiUne/undsenHurunguTovchoo/C1',
    //     component: T2_3_UndsenHurunguTovchoo_C1,
    //     permission: false,
    //   },
    // ],
  },
  T3_2_TatvarSuuriGuilgeeBalance: {
    title: 'Татварын суурь - Гүйлгээ баланс',
    path: '/main/tatvarSuuri/guilgeeBalance',
    component: T3_2_TatvarSuuriGuilgeeBalance,
    permission: false,
    children: [
      {
        title: 'Татварын суурь - Мөнгөн хөрөнгө',
        path: '/main/tatvarSuuri/guilgeeBalance/mungunHurungu',
        component: T3_2_TatvarSuuriGuilgeeBalance_Cash,
        permission: false,
      },
    ],
  },
  T3_3_TatvarSuuriAvlagaUglugTovchoo: {
    path: '/main/tatvarSuuri/avlagaUglugTovchoo',
    component: T3_3_TatvarSuuriAvlagaUglugTovchoo,
    permission: false,
  },
  T3_4_TatvarSuuriUndsenHurungu: {
    title: 'ТС-Үндсэн хөрөнгийн товчоо',
    path: '/main/tatvarSuuri/undsenHurungu',
    component: T3_4_TatvarSuuriUndsenHurungu,
    permission: false,
    // children: [
    //   {
    //     title: 'Татвар ҮХ-ийн баримтын жагсаалт',
    //     path: '/main/tatvarSuuri/undsenHurungu/Jagsaalt',
    //     component: T3_4_TatvarSuuriUndsenHurungu_Jagsaalt,
    //     permission: false,
    //   },
    // ],
  },
  T3_1_TatvarSuuriZuruu: {
    title: 'Нийт данс',
    path: '/main/tatvarTohirgoo/dansniiUneZuruu',
    component: T3_1_TatvarSuuriZuruu,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/tatvarTohirgoo/dansniiUneZuruu/avlagaUglug',
        component: T3_1_TatvarSuuriZuruu_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/tatvarTohirgoo/dansniiUneZuruu/undsenHurungu',
        component: T3_1_TatvarSuuriZuruu_Asset,
        permission: false,
      },
    ],
  },
  T4_1_TatvarShalgahBalance: {
    title: 'Татварын шалгах баланс',
    path: '/main/tatvarTohirgoo/shalgahBalance',
    component: T4_1_TatvarShalgahBalance,
    permission: false,
    children: [
      {
        title: 'Татварын тохируулгын жагсаалт',
        path: '/main/tatvarTohirgoo/shalgahBalance/tohirgoo',
        component: T4_1_TatvarShalgahBalance_Tohirgoo,
        permission: false,
      },
      {
        title: 'Тохиргооны загвар тооцоолол',
        path: '/main/tatvarTohirgoo/shalgahBalance/bulegTohirgoo',
        component: T4_1_TatvarShalgahBalance_Buleg,
        permission: false,
      },
      {
        title: 'Ханшийн тохируулга',
        path: '/main/tatvarTohirgoo/shalgahBalance/hanshTohirgoo',
        component: T4_1_TatvarShalgahBalance_HanshTohirgoo,
        permission: false,
      },
    ],
  },
  T4_2_ZardalHyazgaarCalculate: {
    title: 'Нийт зардлын хязгаар',
    path: '/main/tatvarTohirgoo/hyazgaar',
    component: T4_2_ZardalHyazgaarCalculate,
    permission: false,
    children: [
      {
        title: 'Томилолтын зардлын хязгаар',
        path: '/main/tatvarTohirgoo/hyazgaar/tomilolt',
        component: T4_2_ZardalHyazgaarCalculate_Tomilolt,
        permission: false,
      },
      {
        title: 'Хүүгийн зардлын хязгаар',
        path: '/main/tatvarTohirgoo/hyazgaar/huu',
        component: T4_2_ZardalHyazgaarCalculate_Huu,
        permission: false,
      },
      {
        title: 'Урсгал засварын зардлын хязгаар',
        path: '/main/tatvarTohirgoo/hyazgaar/zasvar',
        component: T4_2_ZardalHyazgaarCalculate_Zasvar,
        permission: false,
      },
    ],
  },
  T4_3_Hungulult: {
    path: '/main/tatvarTohirgoo/hungulult',
    component: T4_3_Hungulult,
    permission: false,
  },
  T5_1_TurZuruu: {
    title: 'Нийт данс',
    path: '/main/hoishlogdsonTatvar/turZuruu',
    component: T5_1_TurZuruu,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/hoishlogdsonTatvar/turZuruu/avlagaUglug',
        component: T5_1_TurZuruu_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/hoishlogdsonTatvar/turZuruu/undsenHurungu',
        component: T5_1_TurZuruu_Asset,
        permission: false,
      },
    ],
  },
  T5_5_TatvarDundajHuvi: {
    path: '/main/hoishlogdsonTatvar/taxAvgPer',
    component: T5_5_TatvarDundajHuvi,
    permission: false,
  },
  T5_2_HoishlogdsonTatvarHurungu: {
    title: 'Нийт данс',
    path: '/main/hoishlogdsonTatvar/hurungu',
    component: T5_2_HoishlogdsonTatvarHurungu,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/hoishlogdsonTatvar/hurungu/avlagaUglug',
        component: T5_2_HoishlogdsonTatvarHurungu_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/hoishlogdsonTatvar/hurungu/undsenHurungu',
        component: T5_2_HoishlogdsonTatvarHurungu_Asset,
        permission: false,
      },
    ],
  },
  T5_3_HoishlogdsonTatvarUrTulbur: {
    title: 'Нийт данс',
    path: '/main/hoishlogdsonTatvar/urTulbur',
    component: T5_3_HoishlogdsonTatvarUrTulbur,
    permission: false,
    children: [
      {
        title: 'Харилцагч',
        path: '/main/hoishlogdsonTatvar/urTulbur/avlagaUglug',
        component: T5_3_HoishlogdsonTatvarUrTulbur_Client,
        permission: false,
      },
      {
        title: 'Үндсэн хөрөнгө',
        path: '/main/hoishlogdsonTatvar/urTulbur/undsenHurungu',
        component: T5_3_HoishlogdsonTatvarUrTulbur_Asset,
        permission: false,
      },
    ],
  },
  T5_4_Aldagdal: {
    title: 'Алдагдалд хамаарах дүн холбох',
    path: '/main/hoishlogdsonTatvar/aldagdal',
    component: T5_4_Aldagdal,
    permission: false,
    children: [
      {
        title: 'Алдагдлын тооцоолол',
        path: '/main/hoishlogdsonTatvar/aldagdal/tootsoolol',
        component: T5_4_Aldagdal_Tootsooloh,
        permission: false,
      },
    ],
  },
  T7_1_AjliinHusnegt: {
    path: '/main/zuruuTailan/ajliinHusnegt',
    component: T7_1_AjliinHusnegt,
    permission: false,
  },
  T7_2_TailanZuruu: {
    path: '/main/zuruuTailan/zohitsuulahTailan',
    component: T7_2_TailanZuruu,
    permission: false,
  },
  T7_3_DansHudulguun: {
    path: '/main/zuruuTailan/hm02_9',
    component: T7_3_DansHudulguun,
    permission: false,
  },
  T6_1_TailanTatvar: {
    title: 'Маягт TT-02',
    tTitle: 'T6_1_TailanTatvar.title',
    path: '/main/tailan/tatvarTailan',
    component: T6_1_TailanTatvar,
    permission: false,
    children: [
      {
        title: 'Маягт ХМ-02(1)',
        path: '/main/tailan/tatvarTailan/hm02_1',
        component: T6_1_TailanTatvar_HM02_1,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(2)',
        path: '/main/tailan/tatvarTailan/hm02_2',
        component: T6_1_TailanTatvar_HM02_2,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(3а)',
        path: '/main/tailan/tatvarTailan/hm02_3a',
        component: T6_1_TailanTatvar_HM02_3a,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(3б)',
        path: '/main/tailan/tatvarTailan/hm02_3b',
        component: T6_1_TailanTatvar_HM02_3b,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(3в)',
        path: '/main/tailan/tatvarTailan/hm02_3v',
        component: T6_1_TailanTatvar_HM02_3v,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(4)',
        path: '/main/tailan/tatvarTailan/hm02_4',
        component: T6_1_TailanTatvar_HM02_4,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(5)',
        path: '/main/tailan/tatvarTailan/hm02_5',
        component: T6_1_TailanTatvar_HM02_5,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(6)',
        path: '/main/tailan/tatvarTailan/hm02_6',
        component: T6_1_TailanTatvar_HM02_6,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(7)',
        path: '/main/tailan/tatvarTailan/hm02_7',
        component: T6_1_TailanTatvar_HM02_7,
        permission: false,
      },
      {
        title: 'Маягт ХМ-02(8)',
        path: '/main/tailan/tatvarTailan/hm02_8',
        component: T6_1_TailanTatvar_HM02_8,
        permission: false,
      },
    ],
  },
  E_tax: {
    path: '/main/tailan/e_tax',
    component: E_tax,
    permission: false,
  },
};

const Entity = () => {
  let childList = [];
  Object.values(routeList).forEach((i) => {
    const { children = [] } = i;
    if (children.length > 0) {
      childList = concat(childList, children);
    }
  });
  const mainList = [...Object.values(routeList), ...childList];
  return (
    <Switch>
      {mainList.map(({ path, component }, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <Route exact path={path} component={component} key={key} />
      ))}
    </Switch>
  );
};

export default Entity;
